import { logout, useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React, { useState } from "react";
import { Navbar as BsNavbar, NavItem, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { dAppName } from "../../../config";
import { routeNames } from "../../../routes";

import Menu from "./Menu.js";

const Navbar = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    console.log("navbar address: ", address);
    console.log("navbar loggedIn: ", loggedIn);
    const history = useHistory();

    const logOut = async (e) => {
        e.preventDefault();
        logout({ callbackUrl: `/` });
        history.push("/");
    };

    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                <div className="container header">
                    {/* Navbar Brand*/}
                    <a className="navbar-brand" href="/">
                        <img className="navbar-brand-sticky" src="img/logo.png" alt="sticky brand-logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Menu */}
                    <Menu source={'navbar'} />
                </div>
            </nav>
        </header>
    );
};

export default Navbar;
