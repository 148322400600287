import axios from "axios";
import * as config from "../config";

const fetchWalletNFTs = () =>
    async function getWalletNFTs({ address }: { address: string }) {
        try {
            const { data } = await axios.get(
                // `${config.network.gatewayAddress}/address/${address}/esdt`
                `${config.network.apiAddress}/accounts/${address}/nfts?size=1000`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchCollectionNftCount = () =>
    async function getCollectionNftCount(collectionTicker: string) {
        try {
            const { data } = await axios.get(
                `${config.network.apiAddress}/nfts/count?collection=${collectionTicker}`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchNFTDetails = () =>
    async function getNFTDetails(tokenIdentifier: string) {
        try {
            const { data } = await axios.get(
                `${config.network.apiAddress}/nfts/${tokenIdentifier}`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchCollectionsWithCreateRole = () =>
    async function getCollections({ address }: { address: string }) {
        try {
            const { data } = await axios.get(
                config.network.gatewayAddress +
                "/address/" +
                address +
                "/esdts-with-role/ESDTRoleNFTCreate",
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchIssuedCollections = () =>
    async function getCollections({ address }: { address: string }) {
        try {
            const { data } = await axios.get(
                config.network.apiAddress +
                `/accounts/${address}/roles/collections?size=10000`
            );
            console.log(data);
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchNftsInCollection = () =>
    async function getNftsInCollection({ ticker }: { ticker: string }) {
        try {
            // const { data } = await axios.get(
            //     config.network.apiAddress +
            //     `/nfts?size=10000&collection=${ticker}`
            // );
            const { data } = await axios.get(
                config.network.apiAddress +
                `/collections/${ticker}/nfts?size=10000&withSupply=true`
            );
            console.log(data);
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchCollectionProperties = () =>
    async function getCollectionProperties(ticker: { ticker: string }) {
        try {
            const { data } = await axios.get(
                config.network.apiAddress +
                `/collections/${ticker}`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchAccountSmartContracts = () =>
    async function getAccountSmartContracts({ address }: { address: string }) {
        try {
            console.log("Req uri", config.network.apiAddress +
            `/accounts/${address}/contracts?size=10000`);
            const { data } = await axios.get(
                config.network.apiAddress +
                `/accounts/${address}/contracts?size=10000`
            );
            console.log(data);
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

const fetchAccountDetails = () =>
    async function getAccountDetails({ address }: { address: string }) {
        try {
            const { data } = await axios.get(
                config.network.apiAddress +
                `/accounts/${address}`
            );
            return {
                data: data,
                success: data !== undefined,
            };
        } catch (err) {
            return {
                success: false,
            };
        }
    };

export const getWalletNFTs = fetchWalletNFTs();
export const getCollectionNFTCount = fetchCollectionNftCount();
export const getNFTDetails = fetchNFTDetails();
export const getCollectionsWithCreateRole = fetchCollectionsWithCreateRole();
export const getIssuedCollections = fetchIssuedCollections();
export const getNftsInCollection = fetchNftsInCollection();
export const getCollectionProperties = fetchCollectionProperties();
export const getAccountSmartContracts = fetchAccountSmartContracts();
export const getAccountDetails = fetchAccountDetails();
