import React, {useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios"

const ItemMedia = ({ v }) => {
    const [isSendNftVisible, setIsSendNftVisible] = useState({status: false});
    
    console.log(v)
    // const request = ( MediaURL ) =>{
        
    //     return axios({ 
    //             method: 'get',
    //             url: MediaURL,

    //         })
    //         .then(resp => {
    //             //console.log(resp.headers['content-type'])
    //             let blob = resp.data
    //             //console.log(blob)
                
    //             let contentType = resp.headers['content-type']
    //             //let contentData = 'data:image/png;base64,'+ blob
    //             let contentData = MediaURL
            
    //             setIsSendNftVisible({ contentData: contentData, contentType: contentType, status: true })
    //         })
    //         .catch(error => {
                
    //             console.log(error)
    //             console.log(MediaURL)
    //             //request( MediaURL )
    //             setIsSendNftVisible({ status: false })

    //         }); //end axios 
        
    // }    
    
    // const loadMedia = () => {
    //     //console.log( isSendNftVisible )
    //     let payload = v;
        
    //     let MediaURL = ''
    //     if( payload.url ){
    //         MediaURL = payload.url
    //     }else{
    //         MediaURL = v.uris[0]
    //     }
            
    //     request( MediaURL )
    //     //setTimeout( function(){ setIsSendNftVisible(MediaURL); return MediaURL }, 1000);
    //     //return MediaURL
        

    // }
    
    // React.useEffect(() => {
                
    //     loadMedia()
        
        
    // }, []);

    React.useEffect(() => {
        if (v.media === undefined || v.media.length === 0) {
            setIsSendNftVisible({status: false});
            return;
        }
        let mediaInfo = v.media[0];
        console.log(mediaInfo);
        setIsSendNftVisible({ contentData: mediaInfo.url, contentType: mediaInfo.fileType, status: true });
    }, []);

    return (
        <>
            { isSendNftVisible.status == false &&
                <div className="media-preloader pt-5" > <div className="loader"></div> </div>
            } 
            { isSendNftVisible.status && isSendNftVisible.contentType.includes( 'image' ) &&
                <img className="card-img-top" src={isSendNftVisible.contentData} alt="" style={{ height: 'auto', width: '100%' }}/>
            } 
            { isSendNftVisible.status && isSendNftVisible.contentType.includes( 'video' ) &&
                <center>
                <video width="214" height="214" controls autoPlay={true} loop muted>
                    <source src={isSendNftVisible.contentData} type={isSendNftVisible.contentType} />
                    Your browser does not support HTML video.
                </video>
             </center>
            } 
        </>
    );
}

export default ItemMedia;