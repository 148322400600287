import { DappUI } from "@elrondnetwork/dapp-core";
import { useLocation } from "react-router-dom";
import { routeNames } from "../../routes";

const LedgerConnect = () => {
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { from } = Object.fromEntries(query);

    return <DappUI.DappCorePages.UnlockPage callbackRoute={from ?? routeNames.collections} />;
}

export default LedgerConnect;