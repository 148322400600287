import React, { useState, Component } from "react";
import ReactDOM from "react-dom";
import { Navbar as BsNavbar, NavItem, Nav, NavDropdown, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { dAppName } from "../../../config";
import { routeNames } from "../../../routes";
import { logout, useGetAccountInfo } from "@elrondnetwork/dapp-core";

const Menu = (props) => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const history = useHistory();
    const [menuState, setmenuState] = useState(false);

    const logOut = async (e) => {
        e.preventDefault();
        logout({ callbackUrl: `/` });
        history.push("/");
    };

    const brandNameImgStyle = {
        maxHeight: "30px",
    };

    const navbarStyle = {
        background: "black",
    };

    const toggleMenu = (e) => {
        
        if( props.source != 'navbar' || ( props.source == 'navbar' && e.target.className.includes('menu-btn') ) ){
            
            let element = document.getElementById("menu");
            if( element.classList.contains("show") ){
                //setmenuState(false)
                element.classList.remove("show");
                element.classList.remove("d-block");
            }else{
                //setmenuState(true)
                element.classList.add("show");
                element.classList.add("d-block");
            }
            
        }
        
        //window.scrollTo({ top: 0, behavior: 'smooth' });
        //console.log(menuState)

    }

    const isOnLandingPage = () => window.location.href === `${window.location.origin}/`;
    const isOnWhiteListedAuction = () => window.location.href === `${window.location.origin}/whitelisted-auction`;

    
  return (
        <React.Fragment>
                {/* Navbar */}
                <ul className="navbar-nav items mx-auto">
                    <li className="nav-item dropdown">
                        <Link className="nav-link" to="/" onClick={toggleMenu} >Home</Link>
                    </li>
                    <li className="nav-item dropdown d-none">
                        <Link className="nav-link" to="/explore">Explore <i className="fas fa-angle-down ml-1 d-none" /></Link>
                        <ul className="dropdown-menu d-none">
                            <li className="nav-item"><a href="/explore-1" className="nav-link">Explore Style 1</a></li>
                            <li className="nav-item"><a href="/explore-2" className="nav-link">Explore Style 2</a></li>
                            <li className="nav-item"><a href="/explore-3" className="nav-link">Explore Style 3</a></li>
                            <li className="nav-item"><a href="/explore-4" className="nav-link">Explore Style 4</a></li>
                            <li className="nav-item"><a href="/auctions" className="nav-link">Live Auctions</a></li>
                            <li className="nav-item"><a href="/item-details" className="nav-link">Item Details</a></li>
                        </ul>
                    </li>

                    <li className="nav-item">
                        <Link to="/giveaways" className="nav-link" onClick={toggleMenu} >Giveaways</Link>
                    </li>

                    <li className="nav-item">
                        <Link to="/load-wallet" className="nav-link" onClick={toggleMenu} >Explore Wallet</Link>
                    </li>

                    <li className="nav-item d-none">
                        <Link to="/my-collections" className="nav-link" onClick={toggleMenu} >My Collections</Link>
                    </li>
                    
                    {loggedIn
                        ?
                        <li className="nav-item">
                            <Link to="/wallet" className="nav-link" onClick={toggleMenu} > Wallet </Link>
                        </li>
                        : ""
                    }


                    {/* <li className="nav-item dropdown">
                        <a className="nav-link" href="#">Community <i className="fas fa-angle-down ml-1" /></a>
                        <ul className="dropdown-menu">
                            <li className="nav-item"><a href="/blog" className="nav-link">Blog</a></li>
                            <li className="nav-item"><a href="/blog-single" className="nav-link">Blog Single</a></li>
                            <li className="nav-item"><a href="/help-center" className="nav-link">Help Center</a></li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" href="#">Pages <i className="fas fa-angle-down ml-1" /></a>
                        <ul className="dropdown-menu">
                            <li className="nav-item"><a href="/authors" className="nav-link">Authors</a></li>
                            <li className="nav-item"><a href="/author" className="nav-link">Author</a></li>
                            <li className="nav-item"><a href="/wallet-connect" className="nav-link">Wallet Connect</a></li>
                            <li className="nav-item"><a href="/create" className="nav-link">Create</a></li>
                            <li className="nav-item"><a href="/login" className="nav-link">Login</a></li>
                            <li className="nav-item"><a href="/signup" className="nav-link">Signup</a></li>
                        </ul>
                    </li> */}
                    {/* <li className="nav-item">
                        <a href="/contact" className="nav-link">Contact</a>
                    </li> */}
                </ul>
                {/* Navbar Icons */}
                
                <ul className="navbar-nav icons d-none">
                    <li className="nav-item">
                        <a href="#" className="nav-link" data-toggle="modal" data-target="#search">
                            <i className="fas fa-search" />
                        </a>
                    </li>
                </ul>
                
                {/* Navbar Toggler */}
                { props.source == 'navbar' &&
                <ul className="navbar-nav toggle">
                    <li className="nav-item">
                        <a href="#" className="nav-link menu-btn" data-toggle="modal" data-target="#menu"  onClick={toggleMenu} >
                            <i className="fas fa-bars toggle-icon m-0 menu-btn" />
                        </a>
                    </li>
                </ul>
                }

                {/* Navbar Action Button */}
                { props.source == 'navbar' &&
                <ul className="navbar-nav action">
                    <li className="nav-item ml-3">
                        {loggedIn
                        ? <a href="#" className="btn ml-lg-auto btn-bordered-white" onClick={logOut}><i className="icon-wallet mr-md-2" />Disconnect</a>
                        : <Link to={routeNames.walletConnect} className="btn ml-lg-auto btn-bordered-white"><i className="icon-wallet mr-md-2" />Wallet Connect</Link>
                        }
                    </li>
                </ul>
                }
      
        </React.Fragment>

      );
};

export default Menu;
