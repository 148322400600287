import { sendTransactions, useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React from "react";
import { useHistory } from "react-router-dom";
import { getTransferNFTTransaction } from "../../helpers/transactions";

const TransferItem = ({ nftDetails }) => {
    const { address } = useGetAccountInfo();
    const history = useHistory();
    //console.log( nftDetails )
    //console.log( window.location.href )
    
    const [receivingAddress, setReceivingAddress] = React.useState("");
    const handleSendButton = async (e) => {
        e.preventDefault();
        let quantity = parseInt("1"); 
        let sendTransaction = getTransferNFTTransaction(nftDetails.tokenIdentifier, nftDetails.nonce, quantity, address, receivingAddress);
        // send({
        //     transaction: sendTransaction,
        //     callbackRoute: "/wallet"
        // });
        await sendTransactions({
            transactions: sendTransaction,
            transactionsDisplayInfo: {
              processingMessage: 'Transferring NFT',
              errorMessage: 'An error has occured during transfer',
              successMessage: 'Transaction successful',
              transactionDuration: 10000,
            },
            callbackRoute: "/wallet"
          });
    };
    
    const handleTestSendButton = (e) => {
        e.preventDefault();
        
        history.push("/wallet?status=success&txHash=a36d26ae3fd759488670fb3b4f306280873ba4a406ebbdb7e4b5f5b22744077c");
    };
    
    return (
        <section className="item-details-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12">
                        <div className="item-info">
                            <div className="item-thumb text-center col-12 col-md-6 offset-md-3">
                                <img src={nftDetails?.url ?? nftDetails.uris[0]} alt="" className="w-50" />
                            </div>

                        </div>
                    </div>
                    <div className="col-12 mt-5">

                        {/* Content */}
                        <div className="content mt-5 mt-lg-0 text-center">

                            <h4 className="m-0"> Transfer Item </h4>

                            <h3 className="mt-4">{nftDetails.name}</h3>
                            {/* <p>{this.state.initData.content}</p> */}

                            <div className="owner d-flex align-items-center mt-5">

                                <input type="text" placeholder="erd address" className="form-control" onChange={(e) => setReceivingAddress(e.target.value)} />

                            </div>

                            <div className="owner text-center mt-4">

                                <a className="btn btn-bordered-white btn-smaller mt-3" onClick={handleSendButton}>
                                    <i className="icon-envelope mr-2" />Send
                                </a>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

export default TransferItem;