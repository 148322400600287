/*
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyDkB69xriCmmTgCiDiFjWW9vGYMEI3oM2k",
    authDomain: "nftmarketplace-9fbba.firebaseapp.com",
    projectId: "nftmarketplace-9fbba",
    storageBucket: "nftmarketplace-9fbba.appspot.com",
    messagingSenderId: "520874346006",
    appId: "1:520874346006:web:8807849270636111959737",
    measurementId: "G-3Q45K0FP9H"
};

const firebase = initializeApp( firebaseConfig );

export const db = getFirestore()

//db.collection('users').add({ Name: "Flavius" });


// Import the functions you need from the SDKs you need
import { initializeApp } from "https://www.gstatic.com/firebasejs/9.6.1/firebase-app.js";
import { getAnalytics } from "https://www.gstatic.com/firebasejs/9.6.1/firebase-analytics.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
apiKey: "AIzaSyDkB69xriCmmTgCiDiFjWW9vGYMEI3oM2k",
authDomain: "nftmarketplace-9fbba.firebaseapp.com",
projectId: "nftmarketplace-9fbba",
storageBucket: "nftmarketplace-9fbba.appspot.com",
messagingSenderId: "520874346006",
appId: "1:520874346006:web:8807849270636111959737",
measurementId: "G-3Q45K0FP9H"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
*/

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, addDoc, setDoc } from 'firebase/firestore';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkB69xriCmmTgCiDiFjWW9vGYMEI3oM2k",
  authDomain: "nftmarketplace-9fbba.firebaseapp.com",
  projectId: "nftmarketplace-9fbba",
  storageBucket: "nftmarketplace-9fbba.appspot.com",
  messagingSenderId: "520874346006",
  appId: "1:520874346006:web:8807849270636111959737",
  measurementId: "G-3Q45K0FP9H"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);