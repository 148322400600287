import React, {  } from "react";
import WalletInventoryItem from "./WalletItem";

const WalletNFTGallery = ({walletNfts, isSendNftButtonVisible}) => {
    return <div className="row items">
        {walletNfts && walletNfts?.length > 0 && walletNfts.map((v, i) => {
            return <WalletInventoryItem key={i} v={v} isSendNftButtonVisible={isSendNftButtonVisible} />
        })}
    </div>
}

export default WalletNFTGallery;