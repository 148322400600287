import React from "react";
import routes, { routeNames } from "../../routes";
import Navbar from "./Navbar/index.js";
import ScrollToTop from "./ScrollToTop.js";
import ModalMenu from "../Modal/ModalMenu.js";
import Footer from "./Footer/index.js";
import { AuthenticatedRoutesWrapper, useGetAccountInfo } from "@elrondnetwork/dapp-core";

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { address } = useGetAccountInfo();
  const loggedIn = Boolean(address);
  // const refreshAccount = Dapp.useRefreshAccount();

  // React.useEffect(() => {
  //   if (loggedIn) {
  //     refreshAccount();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [loggedIn]);

  const styles = {
    margin: 0,
    display: "grid",
  };

  return (
    <div className="wrapper">
      <ScrollToTop />
      <Navbar />
      <main style={styles}>
        <AuthenticatedRoutesWrapper routes={routes} unlockRoute={routeNames.walletConnect}>
          {children}
        </AuthenticatedRoutesWrapper>
      </main>
      
      <ModalMenu />
      <Footer />
    </div>
  );
};

export default Layout;
