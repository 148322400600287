import React, { Component } from 'react';

import Navbar from "../../components/Layout/Navbar/index.js";
import Explore from '../../components/Explore/ExploreFour.js';
import Footer from '../../components/Layout/Footer/index.js';

class ExploreTwo extends Component {
    render() {
        return (
            <div className="main">
                <Navbar />
                <Explore />
            </div>
        );
    }
}

export default ExploreTwo;