import React, { useState } from "react";
import { Link } from "react-router-dom";

import ItemMedia from '../../components/ItemDetails/ItemMedia';
import Modal from '../../components/Modal/Modal';

const WalletInventoryItem = ({ v, isSendNftButtonVisible }) => {
    const [ModalVisible, setModalVisible] = useState(false);
    console.log(v)

    const openModal = (val) => {
        //setModalVisible(false)
        let value = !ModalVisible
        //console.log(value)
        setModalVisible(value)

    }

    return (
        <>
            <div className="col-12 col-sm-6 col-lg-3 item">
                <div className="card">
                    <div className="image-over">
                        <Link to={`/item?tokenIdentifier=${v.identifier}`}>
                            <ItemMedia v={v} />
                        </Link>
                    </div>
                    {/* Card Caption */}
                    <div className="card-caption col-12 p-0">
                        {/* Card Body */}
                        <div className="card-body">
                            <Link to={`/item?tokenIdentifier=${v.identifier}`}>
                                <h5 className="mb-0 font-weight-normal">{v.name}</h5>
                            </Link>
                            <div className="seller d-flex align-items-center my-3 d-none">
                                <span>Ticker</span>
                                <Link to={`/collection-items?collectionTicker=${v.collection}`} >
                                    <h6 className="ml-2 mb-0 font-weight-normal">{v.collection}</h6>
                                </Link>
                            </div>
                            {v.type === 'SemiFungibleESDT' && <div className="seller d-flex align-items-center my-3 d-none">
                                {v.balance && <>
                                    <span>Balance</span>
                                    <Link to={`/collection-items?collectionTicker=${v.collection}`} >
                                        <h6 className="ml-2 mb-0 font-weight-normal">{v?.balance}</h6>
                                    </Link>
                                </>}
                                {v.supply && <>
                                    <span>Supply</span>
                                    <Link to={`/collection-items?collectionTicker=${v.collection}`} >
                                        <h6 className="ml-2 mb-0 font-weight-normal">{v?.supply}</h6>
                                    </Link>
                                </>}
                            </div>}

                            {isSendNftButtonVisible && <a className="btn btn-bordered-white btn-smaller mt-3 " onClick={openModal} data-toggle="modal" data-target={`#transfer-nft-${v.identifier}`} >
                                <i className="icon-envelope mr-2" /> Send
                            </a>}

                            {/* <Link className="btn btn-bordered-white btn-smaller mt-3 d-none" to={`/transfer-item?tokenIdentifier=${v.identifier}`} >
                                <i className="icon-envelope mr-2" />Send
                            </Link> */}



                        </div>
                    </div>
                </div>
            </div>

            {ModalVisible && <Modal parentStateSetter={openModal} modalDetails={{ target: 'trasnferItem', details: v }} />}

        </>
    )
}

export default WalletInventoryItem;