import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React from "react";

import ItemMedia from './ItemMedia';

const NFTDetails = ({ nftDetails }) => {
    const { address } = useGetAccountInfo();
    const [description, setDescription] = React.useState("");
    const [tags, setTags] = React.useState([]);
    const [didMount, setDidMount] = React.useState(false);
    console.log(nftDetails);
    const getDescription = () => {
        if (nftDetails.metadata) {
            return nftDetails.metadata.description;
        }
        if (nftDetails.attributes) {
            for (var i = 0; nftDetails.attributes.length; i++) {
                if (nftDetails.attributes[i] && nftDetails.attributes[i].length > 0 && nftDetails.attributes[i][0].toLowerCase() == "description")
                    return nftDetails.attributes[i][1];
            }
        }
        return null;
    }

    const getTags = () => {
        
        console.log( nftDetails )
        
        let result = []
        if (nftDetails.tags) {
            //return nftDetails.tags;
        }
        if (nftDetails.attributes) {
            for (var i = 0; nftDetails.attributes.length; i++) {
                //if (nftDetails.attributes[i][0].toLowerCase() == "tags"){
                    //return nftDetails.attributes[i][1].split(',');
                //}
            }
        }
       
        setTags(result);
        
    }
    // getDescription();
    React.useEffect(() => {
        if (didMount)
            return;
        
        console.log( nftDetails )
        setDescription(getDescription());
        
        //getTags()

        //setDidMount(true);
    }, []);

    return (
        <section className="item-details-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-5">
                        <div className="item-info">
                            <div className="item-thumb text-center">
                                <ItemMedia v={nftDetails} />
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-6">

                        {/* Content */}
                        <div className="content mt-5 mt-lg-0">
                            <h3 className="m-0 font-weight-normal">{nftDetails.name}</h3>
                            {description && <div className="owner-meta d-flex align-items-center ml-3">
                                {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                <h6 className="ml-2 font-weight-normal">{description}</h6>
                            </div>}
                            {tags && tags.length > 0 && <div className="owner d-flex align-items-center">
                                <span>Tags</span>
                                <div className="owner-meta d-flex align-items-center ml-3">
                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                    <h6 className="ml-2 font-weight-normal">{tags.join('; ')}</h6>
                                </div>
                            </div>}
                            {/* <p>{this.state.initData.content}</p> */}
                            {/* Owner */}
                            <div className="owner d-flex align-items-center">
                                <span>Collection</span>
                                <a className="owner-meta d-flex align-items-center ml-3" href={`/collection-items?collectionTicker=${nftDetails.collection}`} target="_blank">
                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                    <h6 className="ml-2 font-weight-normal">{nftDetails.collection}</h6>
                                </a>
                            </div>
                            {nftDetails.type === 'NonFungibleESDT' && nftDetails.owner != address && <div className="owner d-flex align-items-center">
                                <span>Owner</span>
                                <a className="owner-meta d-flex align-items-center ml-3" href={`/load-wallet?lookup=${nftDetails.owner}`} target="_blank">
                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                    <h6 className="ml-2">erd1...{nftDetails.owner.substring(56)}</h6>
                                </a>
                            </div>}
                            <div className="owner d-flex align-items-center">
                                <span>Royalties</span>
                                <div className="owner-meta d-flex align-items-center ml-3">
                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                    <h6 className="ml-2">{nftDetails.royalties ?? "N/A"}%</h6>
                                </div>
                            </div>
                            <div className="owner d-flex align-items-center">
                                <span>Nonce</span>
                                <div className="owner-meta d-flex align-items-center ml-3">
                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                    <h6 className="ml-2">{nftDetails.nonce ?? "N/A"}</h6>
                                </div>
                            </div>
                            {nftDetails.type === 'SemiFungibleESDT' && <div className="owner d-flex align-items-center">
                                <span>Supply</span>
                                <div className="owner-meta d-flex align-items-center ml-3">
                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                    <h6 className="ml-2">{nftDetails.supply ?? "N/A"}</h6>
                                </div>
                            </div>}
                             
                            { nftDetails.attributes.length > 0 && nftDetails.attributes.map((pair, idx) => {
                                    if( pair[0].toLowerCase() == "description" ){
                                    return <  >
                                        {
                                            
                                                <div className="owner d-flex align-items-center">
                                                    <span>Description</span>
                                                    {/* <img className="avatar-sm rounded-circle" src={this.state.initData.ownerImg} alt="" /> */}
                                                    <h6 className="ml-2"> {pair[1]} </h6>
                                                </div>
                                            
                                                
                                        }
                                    </>
                                    }
                                })}
                             
                            {/* Netstorm Tab */}
                            {/* <ul className="netstorm-tab nav nav-tabs" id="nav-tab">
                                <li>
                                    <a className="active" id="nav-attributes-tab" data-toggle="pill" href="#nav-attributes">
                                        <h5 className="m-0">Attributes</h5>
                                    </a>
                                </li>
                            </ul> */}
                            {/* Tab Content */}
                             
                            <div className="tab-content" id="nav-tabContent">
                                <ul className="list-unstyled">
                                    
                                    {  nftDetails.attributes.length > 0 && nftDetails.attributes.map((pair, idx) => {
                                        return <  >
                                            {
                                                pair[0].toLowerCase() == "description" ||
                                                    pair[0].toLowerCase() == "tags"
                                                    ? null
                                                    : <li key={idx}>
                                                        <div className="owner d-flex align-items-center ml-2">
                                                            <span> { pair[0].charAt(0).toUpperCase() + pair[0].slice(1) }: </span>
                                                            <h6 className="ml-2">{pair[1].toUpperCase() }</h6>
                                                        </div>
                                                    </li>
                                            }
                                        </>

                                    })}
                                    
                                    
                                    
                                </ul>
                            </div>
                             
                            {/*
                            <div className="tab-content" id="nav-tabContent">
                                <ul className="list-unstyled">
                                    
                                    {!nftDetails.metadata && nftDetails?.attributes?.length > 0 && nftDetails.attributes.map((pair, idx) => {
                                        return <>
                                            {
                                                pair[0].toLowerCase() == "description" ||
                                                    pair[0].toLowerCase() == "tags"
                                                    ? null
                                                    : <li key={idx}>
                                                        <div className="owner d-flex align-items-center ml-2">
                                                            <span>{pair[0]}</span>
                                                            <h6 className="ml-2">{pair[1]}</h6>
                                                        </div>
                                                    </li>
                                            }
                                        </>

                                    })}
                                    {nftDetails.metadata && nftDetails.metadata.attributes.map((pair, idx) => {
                                        return <li key={idx}>
                                            <div className="owner d-flex align-items-center ml-2">
                                                <span>{pair.trait_type}</span>
                                                <h6 className="ml-2">{pair.value}</h6>
                                            </div>
                                        </li>
                                    })}
                                    {nftDetails.metadata && nftDetails.metadata.rarity &&
                                        <li  >
                                            <div className="owner d-flex align-items-center ml-2">
                                                <span>Rarity score</span>
                                                <h6 className="ml-2">{nftDetails.metadata.rarity.rarityScore}</h6>
                                            </div>
                                        </li>
                                    }
                                </ul>
                            </div>
                        */}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default NFTDetails;