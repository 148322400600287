import { sendTransactions, useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import { getIssueCollectionTransaction, getAssignCreatorRoleTransaction } from "../../helpers/transactions";
import WalletNFTGallery from "../WalletInventory/WalletGallery";
//import TransferNFTModal from "./TransferNFTModal";
import { routeNames } from "../../routes";


const CreateCollection = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [walletNfts, setWalletNfts] = useState([]);
    const [CollectionName, setCollectionName] = useState([]);
    const [CollectionTicker, setCollectionTicker] = useState([]);
    const [CollectionNameError, setCollectionNameError] = useState(false);
    const [CollectionTickerError, setCollectionTickerError] = useState(false);
    const [state, setState] = useState([]);
    const [shouldShowList, setShouldShowList] = useState(false);

    const loadNfts = (address) => {
        let walletNfts = getWalletNFTs({ address }).then(({ data }) => {
            if (data != undefined) {
                let items = Object.entries(data.data.esdts).map(x => x[1]);
                let parsed = [];
                for (var i = 0; i < items.length; i++) {
                    if (!items[i]?.attributes || !items[i]?.uris)
                        continue;
                    parsed.push(transformData(items[i]));
                }
                return data;
            }

        });
        return walletNfts;
    }

    const transformData = (nftItem) => {
        let tokenIdentifierSplit = nftItem.tokenIdentifier.split('-');
        return {
            attributes: Buffer.from(nftItem.attributes, "base64").toString(),
            balance: parseInt(nftItem.balance),
            creator: nftItem.creator,
            name: nftItem.name, //name
            nonce: nftItem.nonce,
            royalties: `${parseInt(nftItem.royalties) / 100} %`,
            fullTokenIdentifier: nftItem.tokenIdentifier,
            tokenIdentifier: `${tokenIdentifierSplit[0]}-${tokenIdentifierSplit[1]}`,
            uris: nftItem.uris.map(u => Buffer.from(u, "base64").toString())
        }
    }

    const handleChangeCollectionName = e => {
        if (/^[a-zA-Z0-9 ]*$/.test(e.target.value)) {
            //this.setState({ CollectionName: e.target.value });
            //state.CollectionName = e.target.value
            setCollectionName(e.target.value)
        }
    }

    const handleChangeCollectionTicker = e => {
        if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setCollectionTicker(e.target.value.toUpperCase())
        }
    }

    const handleSubmit = async e => {

        e.preventDefault();

        setCollectionNameError(true)
        if (CollectionName != "" && CollectionName.length >= 3 && CollectionName.length <= 20) {
            setCollectionNameError(false)
        } else {
            setCollectionNameError(true)
        }

        setCollectionTickerError(true)
        if (CollectionTicker != "" && CollectionTicker.length >= 3 && CollectionTicker.length <= 10) {
            setCollectionTickerError(false)
        } else {
            setCollectionTickerError(true)
        }

        if (CollectionName != "" && CollectionName.length >= 3 && CollectionName.length <= 20 && CollectionTicker != "" && CollectionTicker.length >= 3 && CollectionTicker.length <= 10) {
            //make request
            let transaction = getIssueCollectionTransaction(CollectionName, CollectionTicker);
            // send({ transaction: transaction, callbackRoute: "/wallet" });
            await sendTransactions({
                transactions: transaction,
                transactionsDisplayInfo: {
                    processingMessage: 'Buying NFT',
                    errorMessage: 'An error has occured during buying',
                    successMessage: 'Transaction successful',
                    transactionDuration: 10000
                }
            })
            // getAssignCreatorRolePayload(address, CollectionName, CollectionTicker)
            //make request
        } else {
            //console.log("NOT Create collection")
        }

    }

    // const getAssignCreatorRolePayload = (address, CollectionName, CollectionTicker) => {

    //     console.log(address)
    //     console.log(CollectionName)
    //     console.log(CollectionTicker)

    //     let transaction = getIssueCollectionTransaction({ CollectionName, CollectionTicker });
    //     send({ transaction: transaction, callbackRoute: "/transaction" });
    //     //send({transaction, '/transaction' })
    // }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {
            console.log(address)
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <div className="col-12 col-md-8 offset-md-2">

                <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5 text-center">
                    <div className="intro-content">
                        <span>Get Started</span>
                        <h3 className="mt-3 mb-0">Create Collection</h3>
                    </div>
                </div>

                {!loggedIn &&
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className="mt-5 mb-0">You need to connect your wallet first</h3>
                            <Link to={routeNames.walletConnect} className="btn ml-lg-auto btn-bordered-white mt-5"><i className="icon-wallet mr-md-2" />Wallet Connect</Link>
                        </div>
                    </div>
                }

                {loggedIn &&
                    <form className="item-form card no-hover" >
                        <div className="row">

                            <div className="col-12">
                                <div className="form-group mt-3">
                                    <input type="text" className={'form-control ' + (CollectionNameError ? 'is-invalid' : '')} name="CollectionName" placeholder="Collection Name" required="required" value={CollectionName} onChange={handleChangeCollectionName} />
                                </div>
                                <p className="card-bg p-0 m-0 font-size-12" > *required and must have 3-20 alpha numeric characters </p>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="form-group mt-3">
                                    <input type="text" className={'form-control ' + (CollectionTickerError ? 'is-invalid' : '')} name="CollectionTicker" placeholder="Collection Ticker" required="required" value={CollectionTicker} onChange={handleChangeCollectionTicker} />
                                </div>
                                <p className="card-bg p-0 m-0 font-size-12" > *required and must have 3-10 alpha numeric all caps characters </p>
                            </div>

                            <div className="col-12">
                                <button className="btn w-100 mt-3 mt-sm-4" onClick={handleSubmit} >Create Collection</button>
                            </div>

                        </div>
                    </form>
                }


            </div>

        </div>

    </section>
}

export default CreateCollection;