import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import NFTDetails from '../../components/ItemDetails/TransferItem';

import { getNFTDetails } from "../../helpers/asyncRequests";

const NftDetailsPage = () => {
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [nftDetails, setNftDetails] = useState();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
        const { tokenIdentifier } = Object.fromEntries(query);
    
    const initData = () => {
        console.log( tokenIdentifier )
        if (!componentDidMount && tokenIdentifier) {
            getNFTDetails(tokenIdentifier).then(({ data }) => {
                console.log(data)
                let parsedAttributes = Buffer.from(data.attributes, "base64").toString()
                    .split(';')
                    .map(x => x.split(':'));
                data.attributes = parsedAttributes;
                setNftDetails(data);
            })
            setComponentDidMount(true);
        }
    }

    useEffect(initData, []);

    return (
        <div className="main">
            {componentDidMount && nftDetails && nftDetails?.uris
                && <NFTDetails nftDetails={nftDetails} />
            }
        </div>
    );
}
export default NftDetailsPage;