import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import WalletNFTGallery from "./WalletGallery";
import WalletHeader from "./WalletHeader";
//import TransferNFTModal from "./TransferNFTModal";

import { db } from "../../Firebase.js";
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";

const Wallet = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [inputAddress, setInputAddress] = useState();
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [walletNfts, setWalletNfts] = useState([]);
    const [shouldShowList, setShouldShowList] = useState(false);
    //erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg
    //erd1964efrfe0h0rcg9vck4c4wdmesvatm6860mj8dyk2neudnkuk5jqudz3lz

    const loadNfts = (address) => {
        let walletNfts = getWalletNFTs({ address }).then(({ data }) => {
            if (data != undefined) {
                let items = Object.entries(data.data.esdts).map(x => x[1]);
                let parsed = [];
                for (var i = 0; i < items.length; i++) {
                    if (
                        items[i].uris?.length > 0
                        && !items[i].tokenIdentifier.includes("LKFARM-9d1ea8")
                        && !items[i].tokenIdentifier.includes("MEXFARM-e7af52")
                    ) {
                        parsed.push(transformData(items[i]));
                    }
                }
                return parsed;
            }

        });
        return walletNfts;
    }

    const transformData = (nftItem) => {
        let tokenIdentifierSplit = nftItem.tokenIdentifier.split('-');
        return {
            attributes: Buffer.from(nftItem.attributes || "", "base64").toString(),
            balance: parseInt(nftItem.balance),
            creator: nftItem.creator,
            name: nftItem.name, //name
            nonce: nftItem.nonce,
            royalties: `${parseInt(nftItem.royalties) / 100} %`,
            fullTokenIdentifier: nftItem.tokenIdentifier,
            tokenIdentifier: `${tokenIdentifierSplit[0]}-${tokenIdentifierSplit[1]}`,
            uris: nftItem.uris.map(u => Buffer.from(u, "base64").toString())
        }
    }

    const loadNftsHandler = (e) => {
        e.preventDefault();
        loadNfts(inputAddress).then((val) => {
            setWalletNfts(val);
            setShouldShowList(true);
        });
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {

            let user = {
                wallet: "MAIAR",
                address: address,
                datetime: Math.round(+new Date() / 1000)
            }
            setDoc(doc(db, "users", address), user, { merge: true })

            loadNfts(address).then((val) => {
                setWalletNfts(val);
                setShouldShowList(true);
            });
        }
        setComponentDidMount(true);
    }, []);

    const shouldSendNftButtonBeVisible = () => {
        return loggedIn && inputAddress === address;
    }

    return <section className="explore-area">
        <div className="container">

            <WalletHeader />

            <div className="item-form no-hover d-none">
                <div className="row">
                    <div className="col-9">
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="name" placeholder="erd1 Address" required="required" onChange={(e) => setInputAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group mt-4">
                            <button className="btn content-btn" onClick={(e) => loadNftsHandler(e)} >Show</button>
                        </div>
                    </div>
                </div>
            </div>


            {shouldShowList && walletNfts?.length == 0 &&
                <div className="row">
                    <div className="col-12">
                        <h3 className="mt-5 mb-0">Wallet is empty.</h3>
                    </div>
                </div>
            }

            {shouldShowList && walletNfts?.length > 0
                ? <WalletNFTGallery walletNfts={walletNfts} />
                : <div className="media-preloader pt-5" > <div className="loader"></div> </div>
            }
        </div>
    </section>
}

export default Wallet;