import React, { Component } from 'react';
import {Link} from "react-router-dom";

const initData = {
    pre_heading: "Explore",
    heading: "Exclusive Digital Assets",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    btnText: "Load More"
}

const data = require('../../db/giveaways.json')

class Giveaways extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    }
    render() {
        return (
            <section className="work-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro mb-4 text-center">
                                <div className="intro-content">
                                    <span>How it works?</span>
                                    <h3 className="mt-3 mb-0">Create your own NFTs</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items mt-4">
            
                        <div className="col-12 item">
                            <div className="single-work row">
                                <div className="col-12 col-md-4 text-center" > 
                                    <i className="icons icon-wallet text-effect mt-5" />
                                </div>
                                <div className="col-12 col-md-8" >
                                    <h4>Set up your wallet</h4>
                                    <p>Once you’ve set up your Elrond Wallet, connect to erdNFT by clicking the WalletConnect button in the top right corner.</p>
                                </div>
                            </div>
                        </div>
            
                        <div className="col-12 mt-5 item">
                            <div className="single-work row">
                                <div className="col-12 col-md-4 text-center" > 
                                    <i className="icons icon-grid text-effect mt-5" />
                                </div>
                                <div className="col-12 col-md-8" >
                                    <h4>Create your collection</h4>
                                    <p>Click Create and set up your collection. Choose an identifier and a ticker to represent it, set your roles and start minting.</p>
                                </div>
                            </div>
                        </div>
            
                        <div className="col-12 mt-5 item">
                            <div className="single-work row">
                                <div className="col-12 col-md-4 text-center" > 
                                    <i className="icons icon-drawer text-effect mt-5" />
                                </div>
                                <div className="col-12 col-md-8" >
                                    <h4>Add your NFTs</h4>
                                    <p>Upload your work (image, video, audio, or 3D art), add a title, a description, set royalties, and customize your NFTs with properties, stats, and unlockable content.</p>
                                </div>
                            </div>
                        </div>
            
                        {/* <div className="col-12 col-sm-6 col-lg-4 item">
                            <div className="single-work">
                                <i className="icons icon-bag text-effect" />
                                <h4>Add your NFTs</h4>
                                <p>Upload your work (image, video, audio, or 3D art), add a title, a description, set royalties, and customize your NFTs with properties, stats, and unlockable content.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default Giveaways;