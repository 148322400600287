import { decode } from "bech32-buffer";

export const str2hex = (val: string) => {
    return Buffer.from(val).toString("hex");
};

export const int2hex = (val: number) => {
    let str = val.toString(16);
    if (str.length % 2 == 0)
        return str;
    return "0" + str;
};

export const decodeAddress = (erdAddress: string) => {
    let decoded = decode(erdAddress).data;
    return Buffer.from(decoded || new Uint8Array()).toString("hex");
};