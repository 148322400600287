import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import WalletNFTGallery from "./WalletGallery";
//import WalletHeader from "./WalletHeader";
//import TransferNFTModal from "./TransferNFTModal";

const Inventory = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [inputAddress, setInputAddress] = useState('');
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [walletNfts, setWalletNfts] = useState([]);
    const [shouldShowList, setShouldShowList] = useState(false);
    const [loadNftsButtonPressed, setLoadNftsButtonPressed] = useState(false);
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { lookup } = Object.fromEntries(query);
    
    //erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg
    //erd1964efrfe0h0rcg9vck4c4wdmesvatm6860mj8dyk2neudnkuk5jqudz3lz

    const loadNfts = (address) => {
        let walletNfts = getWalletNFTs({ address }).then(({ data }) => {
            if (data != undefined) {
                // let items = Object.entries(data.data.esdts).map(x => x[1]);
                // let parsed = [];
                // for (var i = 0; i < items.length; i++) {
                //     if (
                //         items[i].uris?.length > 0
                //         && !items[i].tokenIdentifier.includes("LKFARM-9d1ea8")
                //         && !items[i].tokenIdentifier.includes("MEXFARM-e7af52")
                //     ) {
                //         parsed.push(transformData(items[i]));
                //     }
                // }
                return data.filter(d => d.type === 'NonFungibleESDT' || d.type === 'SemiFungibleESDT');
            }

        });
        return walletNfts;
    }

    const transformData = (nftItem) => {
        let tokenIdentifierSplit = nftItem.tokenIdentifier.split('-');
        return {
            attributes: Buffer.from(nftItem.attributes || "", "base64").toString(),
            balance: parseInt(nftItem.balance),
            creator: nftItem.creator,
            name: nftItem.name, //name
            nonce: nftItem.nonce,
            royalties: `${parseInt(nftItem.royalties) / 100} %`,
            fullTokenIdentifier: nftItem.tokenIdentifier,
            tokenIdentifier: `${tokenIdentifierSplit[0]}-${tokenIdentifierSplit[1]}`,
            uris: nftItem.uris.map(u => Buffer.from(u, "base64").toString())
        }
    }

    const loadNftsHandler = (e) => {
        e?.preventDefault();
        setLoadNftsButtonPressed(true);
        // loadNfts(inputAddress).then((val) => {
        //     setWalletNfts(val);
        //     setShouldShowList(true);
        // });
        loadNftsForAddress(inputAddress);
    }

    const loadNftsForAddress = (addr) => {
        loadNfts(addr).then((val) => {
            setWalletNfts(val);
            setShouldShowList(true);
        });
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (lookup) {
            setInputAddress(lookup);
            setLoadNftsButtonPressed(true);
            loadNftsForAddress(lookup);
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <div className="row">
                <div className="col-12">
                    {/* Intro */}
                    <div className="intro text-center">
                        <div className="intro-content text-center">
                            <span>Wallet Inventory</span>
                            <h3 className="mt-3 mb-0">Elrond NFT Wallet Content</h3>
                            <p className="mt-3 mb-0"> Input a wallet below and see it's content </p>
                            <p className="mt-3 mb-0">{inputAddress}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="item-form no-hover mt-5 col-12 col-md-10 offset-md-1">
                <div className="row">
                    <div className="col-9">
                        <div className="form-group mt-3">
                            <input type="text" className="form-control" name="name" placeholder="Wallet Address" required="required" onChange={(e) => setInputAddress(e.target.value)} />
                        </div>
                    </div>
                    <div className="col-3 text-right">
                        <div className="form-group mt-3">
                            <button className="btn btn-smaller btn-bordered-white " onClick={(e) => loadNftsHandler(e)}> Load wallet </button>
                        </div>
                    </div>
                </div>
            </div>


            {shouldShowList && walletNfts?.length == 0 &&
                <div className="row">
                    <div className="col-12">
                        <h3 className="mt-5 mb-0">Wallet is empty.</h3>
                    </div>
                </div>
            }

            {shouldShowList && walletNfts?.length > 0
                ? <WalletNFTGallery walletNfts={walletNfts} />
                : loadNftsButtonPressed && <div className="media-preloader pt-5" > <div className="loader"></div> </div>
            }
        </div>
    </section>
}

export default Inventory;