import { str2hex, decodeAddress, int2hex } from "./encoding";
import { RawTransactionType } from "./types";
import { Address, Transaction, TransactionPayload, TransactionVersion } from "@elrondnetwork/erdjs/out";
import { gasPerDataByte, gasPrice, version, gasLimit as configGasLimit, chainId } from "../config";
import { getChainID } from "@elrondnetwork/dapp-core";
import BigNumber from 'bignumber.js';

export const getIssueCollectionTransaction = (collectionName: string, tickerName: string) => {
    let transaction: RawTransactionType = {
        value: "0.050000000000000000",
        receiver: "erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u",
        data: getIssueCollectionPayload({ collectionName, tickerName }),
        gasLimit: 60000000
    }

    return createTransaction()(transaction);
}

export const getAssignCreatorRoleTransaction = (tokenIdentifier: string, address: string) => {
    let transaction: RawTransactionType = {
        value: "0",
        receiver: "erd1qqqqqqqqqqqqqqqpqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqzllls8a5w6u",
        data: getAssignCreatorRolePayload({ tokenIdentifier, address }),
        gasLimit: 60000000
    }

    return createTransaction()(transaction);
}

export const getTransferNFTTransaction = (tokenIdentifier: string, nonce: number, quantity: number, sendingAddress: string, receivingAddress: string) => {
    let transaction: RawTransactionType = {
        value: "0",
        receiver: sendingAddress,
        data: getSendNFTPayload({ tokenIdentifier, nonce, quantity, receivingAddress }),
        gasLimit: 60000000
    }

    return createTransaction()(transaction);
}

export const getIssueNFTTransaction = (
    sendingAddress: string, //required
    tokenIdentifier: string, //required
    quantityStr: string, //optional
    nftName: string, // required
    tags: string[], // optional
    description: string, // optional
    royaltiesStr: string, // required
    pinataHash: string, //required
    metadataHash: string, //optional
    imageUri: string, // required
    metadataUri: string,
    manualAttributes: string[] //optional
) => {
    let royalties = parseFloat(royaltiesStr) * 100;
    let quantity = 1;
    if (quantityStr) {
        quantity = parseInt(quantityStr);
    }
    let payload = getIssueNFTPayload({ tokenIdentifier, nftName, quantity, tags, description, royalties, pinataHash, metadataHash, imageUri, metadataUri, manualAttributes });
    let transaction: RawTransactionType = {
        value: "0",
        receiver: sendingAddress,
        data: payload,
        gasLimit: 60000000,
    }

    return createTransaction()(transaction);
}

export const getGiveawayTransaction = (scAddress: string, scEndpoint: string) => {
    let transaction: RawTransactionType = {
        value: "0",
        receiver: scAddress,
        data: scEndpoint,
        gasLimit: 10000000,
    }

    return createTransaction()(transaction);
}

const getIssueCollectionPayload = ({ collectionName, tickerName }:
    { collectionName: string, tickerName: string }) => {
    return `issueNonFungible` +
        `@${str2hex(collectionName)}` +
        `@${str2hex(tickerName)}` +
        `@${str2hex("canFreeze")}@${str2hex("true")}` +
        `@${str2hex("canWipe")}@${str2hex("true")}` +
        `@${str2hex("canPause")}@${str2hex("true")}` +
        `@${str2hex("canTransferNFTCreateRole")}@${str2hex("true")}`;
}

const getAssignCreatorRolePayload = ({ tokenIdentifier, address }:
    { tokenIdentifier: string, address: string }) =>
    `setSpecialRole` +
    `@${str2hex(tokenIdentifier)}` +
    `@${decodeAddress(address)}` +
    `@${str2hex("ESDTRoleNFTCreate")}`;

const getSendNFTPayload = ({ tokenIdentifier, nonce, quantity, receivingAddress }:
    { tokenIdentifier: string, nonce: number, quantity: number, receivingAddress: string }) =>
    `ESDTNFTTransfer` +
    `@${str2hex(tokenIdentifier)}` +
    `@${int2hex(nonce)}` +
    `@${int2hex(quantity)}` +
    `@${decodeAddress(receivingAddress)}`;

const getIssueNFTPayload = ({ tokenIdentifier, nftName, quantity, tags, description, royalties, pinataHash, metadataHash, imageUri, metadataUri, manualAttributes }:
    { tokenIdentifier: string, nftName: string, quantity?: number, tags: string[], description: string, royalties: number, pinataHash: string, metadataHash: string, imageUri: string, metadataUri: string, manualAttributes: string[] }) => {
    let attributesArr = [];
    if (tags.length > 0) {
        attributesArr.push(`tags:${tags.join()}`);
    }
    if (description.length > 0) {
        attributesArr.push(`description:${description}`);
    }
    if (metadataUri && metadataUri.length > 0) {
        attributesArr.push(`metadata:${metadataHash}`);
    }
    if (manualAttributes && manualAttributes.length > 0){
        for(var i = 0; i < manualAttributes.length; i++){
            attributesArr.push(manualAttributes[i]);
        }
    }

    let attributes = attributesArr.length > 0 ? attributesArr.join(";") : " ";
    if (!quantity || quantity < 1) {
        quantity = 1;
    }
    let payload = `ESDTNFTCreate` +
        `@${str2hex(tokenIdentifier)}` +
        `@${int2hex(quantity)}` +
        `@${str2hex(nftName)}` +
        `@${int2hex(royalties)}` +
        `@${str2hex(pinataHash)}` +
        `@${str2hex(attributes)}` +
        `@${str2hex(imageUri)}`;

    if (metadataUri && metadataUri.length > 0) {
        payload += `@${str2hex(metadataUri)}`;
    }

    return payload;
}

const createTransaction = () => {
    return (rawTransaction: RawTransactionType) => {
        const gasLimit = rawTransaction.gasLimit;
        return {
            value: new BigNumber(rawTransaction.value),
            data: new TransactionPayload(rawTransaction.data),
            receiver: new Address(rawTransaction.receiver),
            gasLimit: gasLimit,
            chainID: getChainID(),
            version: new TransactionVersion(version),
        };
    }
}