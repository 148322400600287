import React, { useState } from "react";
import {Link} from "react-router-dom";
import { getIssuedCollections, getCollectionsWithCreateRole, getAccountSmartContracts } from "../../helpers/asyncRequests";
import { getAssignCreatorRoleTransaction } from "../../helpers/transactions";
//import WalletNFTGallery from "./WalletGallery";
//import TransferNFTModal from "./TransferNFTModal";
import { useHistory } from 'react-router-dom';
import WalletHeader from "../WalletInventory/WalletHeader";


//
import { db } from "../../Firebase.js";
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import ContractDetailsRow from "./ContractDetailsRow";
//

const initData = {
    pre_heading: "Explore",
    heading: "Exclusive Digital Assets",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    btnText: "Load More"
}

//const data = require('../../db/giveaways.json')

const MyContracts = () => {
    const history = useHistory();
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [walletContracts, setWalletContracts] = React.useState([]);

    React.useEffect(() => {
        // getAccountSmartContracts({address}).then((res) => {
        //     if (res.success) {
        //         setWalletContracts(res.data.map(d => d.address));
        //     }
        // });
    }, []);

    return <section className="explore-area ">
                <div className="container">
                    
                    <WalletHeader />
                    
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 ">
                            {/* Intro */}
                            <div className="intro text-center text-md-left">
                                <h3 className="mt-3 mb-0">My contracts</h3>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 ">
                            <div className="intro text-center text-md-right">
                                <Link to="/create-contract" className="btn ml-lg-auto btn-bordered-white mt-3 disabled"  > <i className="icon-plus mr-2" /> Create </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                            <b>Coming soon</b>
                        </div>
                    </div>

                    {walletContracts?.length > 0 &&
                        
                        <div className="row">
                    
                            <div className="col-12">
                                
                                    {walletContracts.map((item, idx) => {
                                        return (
                                            <ContractDetailsRow address={item} key={idx} />
                                        );
                                    })}
                        
                            </div>
                        </div>
                    }
                </div>
            </section>
                        
}

export default MyContracts;