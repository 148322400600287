import React, { Component } from 'react';
import axios from 'axios';
import {Link} from "react-router-dom";


const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json-2/footer";

class Footer extends Component {
    state = {
        data: {},
        socialData: [],
        widgetData_1: [],
        widgetData_2: []
    }
    componentDidMount(){
        
        /*
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    socialData: res.data.socialData,
                    widgetData_1: res.data.widgetData_1,
                    widgetData_2: res.data.widgetData_2
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
        */
        
    }
    render() {
        return (
            <footer className="footer-area">
                {/* Footer Top */}
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Logo */}
                                    <div className="row" >
                                        <div className="col-auto pt-2" > <img src="/img/logo.png" alt="" />  </div>
                                        <div className="col-auto" > <h4 className="display-inline" > erdNFT </h4>  </div>
                                    </div>
                                    
                                    {/* Social Icons */}
                                    <div className="social-icons d-flex mt-3">
                                        <a className="facebook text-center pt-1" href="https://facebook.com" target="_blank">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                        <a className="twitter text-center pt-1" href="https://twitter.com/erdnft_" target="_blank">
                                            <i className="fab fa-twitter" />
                                        </a>
                                        <a className="instagram text-center pt-1" href="https://www.instagram.com/erdnft" target="_blank">
                                            <i className="fab fa-instagram" />
                                        </a>
                                        <a className="discord text-center pt-1" href="https://discord.com" target="_blank">
                                            <i className="fab fa-discord" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Useful Links</h4>
                                    <ul>
                                        {/* <li><Link to="#">All NFTs</Link></li> */}
                                        <li><Link to="/how-it-works">How It Works</Link></li>
                                        <li><Link to="/create-collection">Create collection</Link></li>
                                        <li><Link to="/create-item">Create item</Link></li>
                                        <li><Link to="/load-wallet">Explore Wallet</Link></li>
                                        {/* <li><Link to="#">Privacy & Terms</Link></li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-3 res-margin">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Community</h4>
                                    <ul>
                                        {/* <li><Link to="#">Help Center</Link></li>
                                        <li><Link to="#">Partners</Link></li>
                                        <li><Link to="#">Blog</Link></li>
                                        <li><Link to="#">Newsletter</Link></li> */}
                                        <li>
                                            <iframe
                                                src='https://egld.community/api/products/9f137ad5-950e-4c98-8efa-68e30f15abe8/upvotes/embed'
                                                width='290'
                                                height='70'
                                                style={{borderRadius: '8px', border: 'none', width: '290px', height: '70px'}}
                                            >
                                            </iframe>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 d-none">
                                {/* Footer Items */}
                                <div className="footer-items">
                                    {/* Footer Title */}
                                    <h4 className="footer-title">Keep in touch</h4>
                                    {/* Subscribe Form */}
                                    <div className="subscribe-form d-flex align-items-center">
                                        <input type="email" className="form-control" placeholder="info@yourmail.com" />
                                        <button type="submit" className="btn"><i className="icon-paper-plane" /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Footer Bottom */}
                <div className="footer-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {/* Copyright Area */}
                                <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                    {/* Copyright Left */}
                                    <div className="copyright-left">©2021 erdNFT, All Rights Reserved.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;