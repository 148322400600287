import React, { Component } from 'react';
import axios from 'axios';
import WalletOptions from './WalletOptions';
import { DappUI } from '@elrondnetwork/dapp-core';

const WalletConnect = () => {
    return (
        <section className="wallet-connect-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-8 col-lg-7">
                        {/* Intro */}
                        <div className="intro text-center">
                            <span>Wallet Connect</span>
                            <h3 className="mt-3 mb-0">Connect your wallet</h3>
                            <p>Connect your Elrond wallet to proceed</p>
                        </div>
                    </div>
                </div>
                {/* <WalletOptions /> */}
                <DappUI.DappCorePages.UnlockPage
                    loginRoute='/'
                />
            </div>
        </section>
    );
}
export default WalletConnect;