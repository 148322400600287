import { routeNames } from "../../routes";
//import Navbar from "../../components/Layout/Navbar/index.js";
import Hero from "../../components/Hero/hero.js";
//import Auctions from "../../components/Auctions/auctions.js";
//import TopSellers from "../../components/TopSellers/topsellers.js";
//import Explore from "../../components/Explore/Explore.js";
import Work from "../../components/Work/Work.js";
import Roadmap from "../../components/Roadmap/Roadmap.js";

const Home = () => {
  const extraMargin = {
    marginBottom: "90px"
  };

  const logoStyling = {
    maxWidth: "100%"
  };
  
  return (
        
        <div className="container">
            <Hero />

            <Work />
            <Roadmap />
            

            <div className="row d-none">
                <div className="col-sm-12">
                    <div className="d-flex justify-content-center" style={extraMargin}>
                        <img src="/logo-text-sm.png" alt="logo" style={logoStyling} />
                    </div>
                </div>
            </div>
            <div className="row d-none">
                <div className="col-sm-12">
                    <div className="d-flex justify-content-center">
                        <a
                            href={routeNames.collections}
                            className="custom-button px-sm-4 m-1 mx-sm-3"
                            style={{ width: "280px" }}
                            >
                        Collections
                        </a>
                    </div>
                </div>
                {/* <div className="col-sm-6">
                <div className="d-flex justify-content-center">
                <a
                href={routeNames.roadmap}
                className="custom-button px-sm-4 m-1 mx-sm-3"
                style={{ width: "280px" }}
                >
                Roadmap
                </a>
                </div>
                </div> */}
            </div>
        </div>
        
        
  );
};

export default Home;
