import React, { Component } from 'react';

const initData = {
    pre_heading: "erdNFT",
    heading: "Discover, create, collect, and sell extraordinary NFTs",
    content: "Explore on the community driven NFT marketplace",
    btn_1: "Explore",
    btn_2: "Create"
}

class Hero extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="hero-section">
                <div className="container">
            
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro d-flex justify-content-between align-items-end m-0">
                                <div className="intro-content">
                                    <span> What we are cooking </span>
                                    <h3 className="mt-3 mb-0">Roadmap</h3>
                                </div>
                                <div className="intro-btn d-none">
                                    <a className="btn content-btn" href="/explore">See details</a>
                                </div>
                            </div>
                        </div>
                    </div>
            
                    <div className="row mt-3">
                        <div className="col-12 col-md-4 text-center">
                            <h2 className="mt-4">Q4 2021</h2>
                        </div>
                        <div className="col-12 col-md-8">
                            <h4>erdNFT pre launch</h4>
                            <p>
                                Website redesign <br></br>
                                Basic blockchain interaction <br />
                                Giveaway functionality (halted)
                            </p>
                            {/* Buttons */}
                            <div className="button-group d-none">
                                <a className="btn btn-bordered-white" href="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</a>
                                <a className="btn btn-bordered-white" href="/create"><i className="icon-note mr-2" />{this.state.data.btn_2}</a>
                            </div>
                        </div>
                    </div>
            
                    <div className="row mt-5">
                        <div className="col-12 col-md-4 text-center">
                            <h2 className="mt-4">Q1 2022</h2>
                        </div>
                        <div className="col-12 col-md-8">
                            <h4>Preparation</h4>
                            <p>
                                Identify market needs <br/>
                                Develop the solution architecture <br/>
                                Discuss future posibilities with artists and NFT projects <br/>
                            </p>
                            {/* Buttons */}
                            <div className="button-group d-none">
                                <a className="btn btn-bordered-white" href="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</a>
                                <a className="btn btn-bordered-white" href="/create"><i className="icon-note mr-2" />{this.state.data.btn_2}</a>
                            </div>
                        </div>
                    </div>
            
                    <div className="row mt-5">
                        <div className="col-12 col-md-4 text-center">
                            <h2 className="mt-4">Q2 2022</h2>
                        </div>
                        <div className="col-12 col-md-8">
                            <h4> Whitepaper development </h4>
                            <p>
                                Research ways to reduce the rugpull attempts <br/> 
                                Prepare tokenomics and run simulations <br/>
                                Define the direction
                            </p>
                            {/* Buttons */}
                            <div className="button-group d-none">
                                <a className="btn btn-bordered-white" href="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</a>
                                <a className="btn btn-bordered-white" href="/create"><i className="icon-note mr-2" />{this.state.data.btn_2}</a>
                            </div>
                        </div>
                    </div>
            
                    <div className="row mt-5">
                        <div className="col-12 col-md-4 text-center">
                            <h2 className="mt-4">Q3 2022</h2>
                        </div>
                        <div className="col-12 col-md-8">
                            <h4> Legal &amp; whitepaper progress, NFT features </h4>
                            <p>
                                Progress on validation system implementation <br />
                                Release first version of whitepaper <br />
                                Improve the community interaction <br />
                                NFT collections management <br />
                                Airdrops <br />
                                Giveaways <br />
                            </p>
                            {/* Buttons */}
                            <div className="button-group d-none">
                                <a className="btn btn-bordered-white" href="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</a>
                                <a className="btn btn-bordered-white" href="/create"><i className="icon-note mr-2" />{this.state.data.btn_2}</a>
                            </div>
                        </div>
                    </div>
                    <div className="row mt-5">
                        <div className="col-12 col-md-4 text-center">
                            <h2 className="mt-4">Q4 2022</h2>
                        </div>
                        <div className="col-12 col-md-8">
                            <h4> Concept beta release &amp; NFT features development</h4>
                            <p>
                                Implement the validation system <br />
                                NFT collections management <br />
                                Airdrops <br />
                                Giveaways <br />
                            </p>
                            {/* Buttons */}
                            <div className="button-group d-none">
                                <a className="btn btn-bordered-white" href="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</a>
                                <a className="btn btn-bordered-white" href="/create"><i className="icon-note mr-2" />{this.state.data.btn_2}</a>
                            </div>
                        </div>
                    </div>
            
                    <div className="row mt-5">
                        <div className="col-12 col-md-4 text-center">
                            <h2 className="mt-4">Q1 2023</h2>
                        </div>
                        <div className="col-12 col-md-8">
                            <h4> Next steps </h4>
                            <p>
                                More to come soon..
                            </p>
                            {/* Buttons */}
                            <div className="button-group d-none">
                                <a className="btn btn-bordered-white" href="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</a>
                                <a className="btn btn-bordered-white" href="/create"><i className="icon-note mr-2" />{this.state.data.btn_2}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Hero;