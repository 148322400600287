import React from "react";
import { getGiveawayTransaction } from "../../helpers/transactions";
import { useHistory } from "react-router-dom";
import { sendTransactions, useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { routeNames } from "../../routes";

const Giveaway = ({ giveawayDetails }) => {
    // const { loggedIn } = Dapp.useContext()
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const history = useHistory();
    const mint = async (e) => {
        e.preventDefault();
        if (!loggedIn) {
            history.push(routeNames.walletConnect);
            return;
        }
        let transaction = getGiveawayTransaction(giveawayDetails.smartContractAddress, giveawayDetails.smartContractEndpoint);
        // send({
        //     transaction: transaction,
        //     callbackRoute: `/transaction`
        // });
        await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Minting giveaway NFT',
                errorMessage: 'An error has occured during minting',
                successMessage: 'Transaction successful',
                transactionDuration: 10000
            }
        });
    }

    const shouldShowBasedOnDate = () => {
        let show = true;
        let now = Date.now();
        if (giveawayDetails.startDateTicks) {
            let startDate = new Date(giveawayDetails.startDateTicks);
            if (startDate > now) {
                show = false;
            }
        }

        let endDate = new Date(giveawayDetails.endDateTicks);
        if (now > endDate) {
            show = false;
        }

        return show;
    }

    return (!shouldShowBasedOnDate() ? null :
        <section className="item-details-area">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-5">
                        <div className="item-info">
                            <div className="item-thumb text-center">
                                {giveawayDetails.fileType == 'img' && <img className="card-img-top" src={`/img/${giveawayDetails.img}`} alt="" />}
                                {giveawayDetails.fileType == 'video' &&
                                    <video width="310" height="310" controls autoPlay={true} loop muted>
                                        <source src={`/video/${giveawayDetails.img}`} type="video/mp4" />
                                        Your browser does not support HTML video.
                                    </video>
                                }
                            </div>

                        </div>
                    </div>
                    <div className="col-12 col-lg-6">

                        {/* Content */}
                        <div className="content mt-3 mt-lg-0">
                            <div className="card-body p-0 p-md-1 mt-4">
                                <h3 className="mb-0 mt-3">{giveawayDetails.title}</h3>

                                <div className="decsription d-flex align-items-center my-3">
                                    <p className="font-size-14" > {giveawayDetails.description} </p>
                                </div>
                                <div className="seller d-flex align-items-center my-3">
                                    <span>Offered By</span>
                                    <a className="ml-2 font-size-14" href={giveawayDetails.twitterUrl} target="_blank">
                                        {giveawayDetails.owner}
                                    </a>
                                </div>
                                <div className="card-bottom row">
                                    <span className="col-12 col-md-auto border-right-gray pl-0 font-size-12" >{giveawayDetails.price}</span>
                                    <span className="col-12 col-md-auto pl-0 font-size-12" > End date: {giveawayDetails.endDate} </span>
                                </div>
                                <a className="btn btn-bordered-white btn-smaller mt-3" onClick={(e) => mint(e)} ><i className="icon-handbag mr-2" /> Mint now </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Giveaway;