import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { getIssueNFTTransaction } from "../../helpers/transactions";
//import { Form, Button, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import axios from 'axios'
import Switch from "react-switch";

import fs from 'fs';
import FormData from 'form-data';
import recursive from 'recursive-fs';
import basePathConverter from 'base-path-converter';
import ReactTooltip from "react-tooltip";
import { sendTransactions, useGetAccountInfo } from "@elrondnetwork/dapp-core";
import { routeNames } from "../../routes";


const CreateCollectionItem = () => {

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { collectionTicker } = Object.fromEntries(query);
    const history = useHistory();

    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);

    const [componentDidMount, setComponentDidMount] = useState(false);

    const [ItemName, setItemName] = useState([]);
    const [ItemNameError, setItemNameError] = useState(false);

    const [ItemDescription, setItemDescription] = useState([]);
    const [ItemDescriptionError, setItemDescriptionError] = useState(false);

    const [ItemSupply, setItemSupply] = useState(1);

    const [ItemPrice, setItemPrice] = useState([]);
    const [ItemPriceError, setItemPriceError] = useState(false);

    const [ItemTag, setItemTag,] = useState([]);
    const [ItemTags, setItemTags] = useState([]);

    const [PropertyName, setPropertyName,] = useState([]);
    const [PropertyValue, setPropertyValue,] = useState([]);
    const [Properties, setProperties] = useState([]);

    const [ItemRoyalty, setItemRoyalty] = useState('0.00');
    const [ItemRoyaltyError, setItemRoyaltyError] = useState(false);

    const [selectedFile, setSelectedFile] = useState([]);
    const [selectedFileError, setSelectedFileError] = useState(false);

    const [metadataSwitch, setmetadataSwitch] = useState(false);

    const [selectedMetaFile, setSelectedMetaFile] = useState(null);
    const [selectedcollectionFile, setselectedcollectionFile] = useState(null);

    //erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg
    //erd1964efrfe0h0rcg9vck4c4wdmesvatm6860mj8dyk2neudnkuk5jqudz3lz

    const handleChangeItemName = e => {
        if (/^[a-zA-Z0-9 ]*$/.test(e.target.value)) {
            //this.setState({ CollectionName: e.target.value });
            //state.CollectionName = e.target.value
            setItemName(e.target.value)
        }
    }

    const handleChangeItemDescription = e => {
        if (/^[a-zA-Z0-9 ]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setItemDescription(e.target.value)
        }
    }

    const handleChangeItemPrice = e => {
        if (/^[0-9]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setItemPrice(e.target.value)
        }
    }

    const handleChangeItemSupply = e => {
        if (/^[0-9]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setItemSupply(e.target.value)
        }
    }

    const handleChangeAddTag = e => {
        //console.log(e)
        //console.log(ItemTag)
        e.preventDefault()
        let oldArray = ItemTags
        if (ItemTag != "") {
            oldArray.push(ItemTag)
        }
        setItemTags(oldArray);
        setItemTag("")
        console.log(ItemTags)
    }

    const handleChangeTag = e => {
        if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setItemTag(e.target.value)
        }
    }

    const handleChangeRemoveTag = (value) => {
        //console.log(id)
        let arr = ItemTags.filter(function (item) {
            return item !== value
        })
        setItemTags(arr);
    }

    const handleChangePropertyName = e => {
        if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setPropertyName(e.target.value)
        }
    }

    const handleChangePropertyValue = e => {
        if (/^[a-zA-Z0-9]*$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setPropertyValue(e.target.value)
        }
    }

    const handleChangeProperty = e => {
        //console.log(e)
        //console.log(ItemTag)
        e.preventDefault()
        let oldArray = Properties
        if (PropertyName != "" && PropertyValue != "") {
            oldArray.push({ name: PropertyName, value: PropertyValue })
        }
        setProperties(oldArray);
        setPropertyName("")
        setPropertyValue("")

    }

    const handleChangeRemoveProperty = (removed) => {
        //console.log(removed)
        let arr = Properties.filter(function (item) {
            return item.name !== removed.name && item.value !== removed.value
        })
        //let arr = Properties.splice(removed, 1);
        setProperties(arr);
    }

    const handleChangeItemRoyalty = e => {
        if (/^[0-9]*(\.[0-9]{0,2})?$/.test(e.target.value)) {
            //this.setState({ CollectionTicker: e.target.value.toUpperCase() });
            //state.CollectionTicker = e.target.value.toUpperCase() 
            setItemRoyalty(e.target.value)
        }
    }

    const handleChangeItemImagefile = e => {
        let image = e.target.files[0]
        image.value = e.target.value
        //console.log( e.target.files[0] )
        //console.log( e.target.value )
        image.src = URL.createObjectURL(e.target.files[0])
        console.log(image)
        setSelectedFile(image)
    }

    const handleSwitchChange = (checked) => {
        setmetadataSwitch(checked)
    }

    const handleChangeItemMetafile = e => {
        //console.log( e.target.files[0] )
        //console.log( e.target.value )
        setSelectedMetaFile(e.target.files[0])
    }

    const handleChangeItemCollectionfile = e => {
        setselectedcollectionFile(e.target.files[0])
    }

    const pinDirectoryToIPFS = (pinataApiKey, pinataSecretApiKey) => {
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        //const src = './';
        const src = './' + collectionTicker + "_" + Date.now() / 1000;

        //we gather the files from a local directory in this example, but a valid readStream is all that's needed for each file in the directory.
        recursive.readdirr(src, function (err, dirs, files) {
            let data = new FormData();
            files.forEach((file) => {
                //for each file stream, we need to include the correct relative file path
                data.append(`file`, fs.createReadStream(file), {
                    filepath: basePathConverter(src, file)
                });
            });

            let metadata = JSON.stringify({
                name: ItemName,
                fileType: selectedFile.type,
                //keyvalues: {
                //    exampleKey: 'exampleValue'
                //}
            });

            if (ItemDescription != '') {
                metadata.description = ItemDescription
            }

            if (ItemTags.length > 0) {
                metadata.tags = ItemTags.join(",")
            }
            data.append('pinataMetadata', metadata);

            return axios
                .post(url, data, {
                    maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large directories
                    headers: {
                        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                        pinata_api_key: pinataApiKey,
                        pinata_secret_api_key: pinataSecretApiKey
                    }
                })
                .then(function (response) {
                    console.log(response)
                    //handle response here
                })
                .catch(function (error) {
                    console.log(error)
                    //handle error here
                });
        });
    };

    const PINATA_pinFileToIPFS = () => {


        //we gather a local file for this example, but any valid readStream source will work here.
        let data = new FormData();
        data.append('file', selectedFile);

        var keyvalues = Properties.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {});

        //You'll need to make sure that the metadata is in the form of a JSON object that's been convered to a string
        //metadata is optional
        let metadata = {
            name: ItemName,
            fileType: selectedFile.type,
            keyvalues: keyvalues
        };

        if (ItemDescription != '') {
            metadata.description = ItemDescription
        }

        if (ItemTags.length > 0) {
            metadata.tags = ItemTags.join(",")
        }
        data.append('pinataMetadata', JSON.stringify(metadata));


        //console.log( data )
        upload(data)
            .then(async (Imageresult) => {
                console.log(Imageresult)

                let ImageMetaResponse = null
                if (selectedMetaFile != null || Properties.length > 0) {
                    let ImageMedataFile = new FormData()
                    if (selectedMetaFile != null) {
                        ImageMedataFile.append('file', selectedMetaFile);
                    } else if (Properties.length > 0) {
                        let metdataContent = {
                            description: ItemDescription,
                            attributes: Properties
                        }

                        const blob = new Blob([JSON.stringify(metdataContent)], { type: 'application/json' });

                        ImageMedataFile.append('file', blob);

                    }
                    ImageMedataFile.append('pinataMetadata', JSON.stringify(metadata));


                    ImageMetaResponse = upload(ImageMedataFile)
                        .then(async (ImageMetaResult) => {

                            let pinataHash = Imageresult.hash
                            let imageUri = Imageresult.URI
                            let metadataHash = ImageMetaResult.hash
                            let metadataUri = ImageMetaResult.URI

                            //let quantity = 1; // NFT;

                            let transaction = getIssueNFTTransaction(address, collectionTicker, ItemSupply, ItemName, ItemTags, ItemDescription, ItemRoyalty, pinataHash, metadataHash, imageUri, metadataUri, []);
                            // send({ transaction: transaction, callbackRoute: "/wallet" });
                            await sendTransactions({
                                transactions: transaction,
                                transactionsDisplayInfo: {
                                    processingMessage: 'Minting NFT',
                                    errorMessage: 'An error has occured during minting',
                                    successMessage: 'Transaction successful',
                                    transactionDuration: 10000,
                                    callbackRoute: "/wallet"
                                }
                            });
                        })

                } else {

                    let pinataHash = Imageresult.hash
                    let imageUri = Imageresult.URI
                    let metadataHash = null
                    let metadataUri = null

                    console.log(Imageresult)
                    console.log("props: ")
                    console.log(Properties);
                    let manualAttr = [];
                    for (var i = 0; i < Properties.length; i++) {
                        manualAttr.push(`${Properties[i].name}:${Properties[i].value}`)
                    }
                    let transaction = getIssueNFTTransaction(address, collectionTicker, ItemSupply, ItemName, ItemTags, ItemDescription, ItemRoyalty, pinataHash, metadataHash, imageUri, metadataUri, manualAttr);
                    await sendTransactions({
                        transactions: transaction,
                        transactionsDisplayInfo: {
                            processingMessage: 'Minting NFT',
                            errorMessage: 'An error has occured during minting',
                            successMessage: 'Transaction successful',
                            transactionDuration: 10000,
                            callbackRoute: "/wallet"
                        }
                    });
                }

            })

    };

    const upload = (data) => {
        const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
        let pinataApiKey = '22efa6bdc5c7989dbae4'
        let pinataSecretApiKey = 'b38beb45cedc08da482bf2106958211dbf631ede0d4780cadb3e4315cfe547fc'

        return axios
            .post(url, data, {
                maxBodyLength: 'Infinity', //this is needed to prevent axios from erroring out with large files
                headers: {
                    'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
                    pinata_api_key: pinataApiKey,
                    pinata_secret_api_key: pinataSecretApiKey
                }
            })
            .then(function (response) {
                console.log(response)
                //handle response here
                //https://gateway.pinata.cloud/ipfs/QmU7hN4jWzWDN1osFNFKNjBm9c8xT6xHjUkUUKET3FKJxf
                let hash = response.data.IpfsHash
                let URI = 'https://gateway.pinata.cloud/ipfs/' + hash

                return { hash: hash, URI: URI }



            })
            .catch(function (error) {
                //handle error here
            });

    }

    const NFTSTORAGE_uploadData = (data, type) => {

        let APIKEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDUyRDg0NTNjMGQyMTUzMThjNkU2QTg5Y0NhNTA5NTIwMEY0NGM5MDgiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY0MTU0Mzc3ODY0NCwibmFtZSI6ImVyZE5GVCJ9.eW9YD8IySzXKKGVcUIkLFk85UUOwc7XVkuvTWFDhuBU'
        let url = 'https://api.nft.storage/upload'

        return axios
            .post(url, data, {
                headers: {
                    'Content-Type': type,
                    'Authorization': 'Bearer ' + APIKEY,
                }
            })
            .then(function (response) {
                console.log(response)

                return response.data.value.cid
                //handle response here
            })
            .catch(function (error) {
                console.log(error)
                //handle error here
            });


    }

    const NFTSTORAGE_processUpload = () => {

        console.log(ItemName)
        console.log(ItemDescription)
        console.log(selectedFile)

        let ImageData = selectedFile
        let type = selectedFile.type

        NFTSTORAGE_uploadData(ImageData, type)
            .then(async (Imageresult) => {

                console.log(Imageresult)

                let ImageMetaResponse = null
                if (selectedMetaFile != null || Properties.length > 0) {
                    //let ImageMedataFile = new FormData()
                    let ImageMedataFile = null
                    if (selectedMetaFile != null) {
                        //ImageMedataFile.append('file', selectedMetaFile);
                        ImageMedataFile = selectedMetaFile;
                    } else if (Properties.length > 0) {
                        let metdataContent = {
                            description: ItemDescription,
                            attributes: Properties
                        }

                        const blob = new Blob([JSON.stringify(metdataContent)], { type: 'application/json' });

                        ImageMedataFile = blob

                    }
                    //ImageMedataFile.append('pinataMetadata', JSON.stringify(metadata));


                    ImageMetaResponse = NFTSTORAGE_uploadData(ImageMedataFile, 'application/json')
                        .then(async (ImageMetaResult) => {

                            let pinataHash = Imageresult
                            let imageUri = 'https://' + Imageresult + '.ipfs.dweb.link'
                            let metadataHash = ImageMetaResult
                            let metadataUri = 'https://' + ImageMetaResult + '.ipfs.dweb.link'

                            //let quantity = 1; // NFT;

                            let transaction = getIssueNFTTransaction(address, collectionTicker, ItemSupply, ItemName, ItemTags, ItemDescription, ItemRoyalty, pinataHash, metadataHash, imageUri, metadataUri, []);
                            await sendTransactions({
                                transactions: transaction,
                                transactionsDisplayInfo: {
                                    processingMessage: 'Minting NFT',
                                    errorMessage: 'An error has occured during minting',
                                    successMessage: 'Transaction successful',
                                    transactionDuration: 10000,
                                    callbackRoute: "/wallet"
                                }
                            });
                        })

                } else {

                    let pinataHash = Imageresult
                    let imageUri = 'https://' + Imageresult + '.ipfs.dweb.link'
                    let metadataHash = null
                    let metadataUri = null

                    console.log(Imageresult)
                    console.log("props: ")
                    console.log(Properties);
                    let manualAttr = [];
                    for (var i = 0; i < Properties.length; i++) {
                        manualAttr.push(`${Properties[i].name}:${Properties[i].value}`)
                    }
                    let transaction = getIssueNFTTransaction(address, collectionTicker, ItemSupply, ItemName, ItemTags, ItemDescription, ItemRoyalty, pinataHash, metadataHash, imageUri, metadataUri, manualAttr);
                    await sendTransactions({
                        transactions: transaction,
                        transactionsDisplayInfo: {
                            processingMessage: 'Minting NFT',
                            errorMessage: 'An error has occured during minting',
                            successMessage: 'Transaction successful',
                            transactionDuration: 10000,
                            callbackRoute: "/wallet"
                        }
                    });
                }

            })

    }

    const handleSubmit = e => {

        e.preventDefault();

        //console.log(ItemName)
        //console.log(ItemDescription)
        //console.log(ItemRoyalty)
        //console.log(selectedFile)
        //console.log(ItemTags)
        console.log(selectedMetaFile)
        //console.log(selectedcollectionFile)


        setItemNameError(true)
        if (ItemName != "" && ItemName.length >= 3 && ItemName.length <= 20) {
            setItemNameError(false)
        } else {
            setItemNameError(true)
        }

        setItemRoyaltyError(true)
        if (ItemRoyalty != "" && ItemRoyalty >= 0 && ItemRoyalty <= 100) {
            setItemRoyaltyError(false)
        } else {
            setItemRoyaltyError(true)
        }

        setSelectedFileError(true)
        if (selectedFile != "") {
            setSelectedFileError(false)
        } else {
            setSelectedFileError(true)
        }

        if (ItemName != "" && ItemName.length >= 3 && ItemName.length <= 20 && ItemRoyalty != "" && ItemRoyalty >= 0 && ItemRoyalty <= 100 && selectedFile != "") {
            console.log("Create NFT")

            //UPLOAD TO PINATA
            //PINATA_pinFileToIPFS()
            //UPLOAD TO PINATA

            //UPLOAD TO NFT STORAGE
            NFTSTORAGE_processUpload()
            //UPLOAD TO NFT STORAGE


        } else {
            console.log("Issues")
        }

    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {
            console.log(collectionTicker)
            if (collectionTicker == undefined) {
                history.push('/my-collections')
            }
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <div className="col-12">

                <div className="intro mt-2 mt-lg-0 mb-4 mb-lg-5 text-center">
                    <div className="intro-content">
                        <span>Get Started</span>
                        <h3 className="mt-3 mb-0">Add item to collection {collectionTicker != undefined && <span> {collectionTicker} </span>} </h3>
                    </div>
                </div>

                <div className="row d-none">
                    <div className="col-12">
                        <h1 className="mt-5 mb-0 text-center"><i className="fas fa-cogs"></i></h1>
                        <h3 className="mt-5 mb-0 text-center">In the works, coming soon...</h3>
                    </div>
                </div>

                {!loggedIn &&
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className="mt-5 mb-0">You need to connect your wallet first</h3>
                            <Link to={routeNames.walletConnect} className="btn ml-lg-auto btn-bordered-white mt-5"><i className="icon-wallet mr-md-2" />Wallet Connect</Link>
                        </div>
                    </div>
                }

                {loggedIn &&
                    <form className="item-form card no-hover p-0">
                        <div className="row">

                            <div className="col-12 col-md-6 pt-4" >
                                {/* LEFT */}
                                <div className="col-12">
                                    {selectedFile == '' &&
                                        <div className="col-12 col-md-10 offset-md-1 p-2 bg-white border-radius text-center"  > <img src="img/metamask.png" className="w-100" /> </div>
                                    }

                                    {selectedFile != '' && selectedFile.type.includes('image') &&
                                        <div className="col-12 col-md-10 offset-md-1 p-2 bg-white border-radius text-center"  > <img src={selectedFile.src} className="w-100" /> </div>
                                    }
                                    {ItemName != '' &&
                                        <div className="col-12 mt-4"  > <h3 className="m-0">{ItemName}</h3> </div>
                                    }
                                    {collectionTicker != '' &&
                                        <div className="col-12 mt-4 "  > <h6 className="m-0">{collectionTicker}</h6> </div>
                                    }
                                    {ItemDescription != '' &&
                                        <div className="col-12 mt-4 "  > <h6 className="m-0">{ItemDescription}</h6> </div>
                                    }

                                    {ItemTags.length > 0 &&
                                        <div className="col-12 mt-4 mb-3 p-0 ItemTags" >
                                            <h6 className="d-inline">Tags: </h6>
                                            {ItemTags.map((item, idx) => {
                                                return (
                                                    <span className="tag-span mr-2" key={`exf_${idx}`}>
                                                        {item}
                                                        <i className="fas fa-times ml-2" onClick={() => handleChangeRemoveTag(item)} />
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    }

                                    {Properties.length > 0 &&
                                        <div className="col-12 mt-4 mb-3 p-0 ItemTags" >
                                            <h6 className="">Properties: </h6>
                                            {Properties.map((item, idx) => {
                                                return (
                                                    <span className="tag-span mr-2" key={`exf_${idx}`}>
                                                        {item.name} : {item.value}
                                                        <i className="fas fa-times ml-2 pl-2 pr-2" onClick={() => handleChangeRemoveProperty(item)} />
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    }

                                </div>
                                {/* LEFT */}
                            </div>

                            <div className="col-12 col-md-6 p-0" >
                                {/* RIGHT */}
                                {collectionTicker == undefined &&
                                    <div className="col-12">
                                        <div className="form-group mt-3">
                                            <select className="form-control" aria-label="Select Collection">
                                                <option> -- Select collection -- </option>

                                            </select>
                                        </div>
                                    </div>
                                }

                                <div className="col-12">
                                    <div className="form-group mt-3" data-tip="The name of the NFT">
                                        <label>Item Name <span className="font-size-12" > ( *required ) </span>  </label>
                                        <input type="text" className={'form-control ' + (ItemNameError ? 'is-invalid' : '')} name="name" placeholder="Item Name" required="required" value={ItemName} onChange={handleChangeItemName} />
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="form-group" data-tip="The NFT or NFT collection description. Optional field, included in attributes">
                                        <label>Item Description</label>
                                        <textarea className="form-control" name="textarea" placeholder="Description" cols={30} rows={3} value={ItemDescription} onChange={handleChangeItemDescription} />
                                    </div>
                                </div>

                                {/*
                                <div className="col-12 col-md-6 d-none">
                                    <div className="form-group">
                                        <label>Item Price</label>
                                        <input type="text" className="form-control" name="price" placeholder="Item Price" required="required" value={ItemPrice} onChange={handleChangeItemPrice}  />
                                    </div>
                                </div>
                                */}

                                <div className="col-12">
                                    <div className="form-group" data-tip="The percentage to be received when this NFT is resold (if the NFT is sold for 10 EGLD, the owner gets 0.1 for a 1% royalties setting">
                                        <label>Item Royalties <span className="font-size-12" > ( *required ) </span> </label>
                                        <input type="text" className={'form-control ' + (ItemRoyaltyError ? 'is-invalid' : '')} name="royality" step='0.01' pattern="^\d*(\.\d{0,2})?$" placeholder='0.00' required="required" value={ItemRoyalty} onChange={handleChangeItemRoyalty} />
                                    </div>
                                </div>

                                {/* <div className="col-12 ">
                                    <div className="form-group" data-tip="Represents the number of items to be minted. Quantities larger than 1 only apply to SFTs (Semi Fungible Tokens). Leave 1 to mint an NFT">
                                        <label>Supply <span className="font-size-12" > ( default 1 ) </span> </label>
                                        <input type="text" className="form-control" name="royality" step='1' value='1' placeholder='1' value={ItemSupply} onChange={handleChangeItemSupply} />
                                    </div>
                                </div> */}

                                <div className="col-12 ">
                                    <div className="form-group" data-tip="NFT tags. Included in the attributes field">
                                        <label>Item Tags</label>

                                        <div className="row" >
                                            <div className="col-8 col-md-6 p-0" >
                                                <input type="text" className="form-control" name="tag" placeholder="Add tag" value={ItemTag} onChange={handleChangeTag} />
                                            </div>
                                            <div className="col-4 col-md-6 p-0" >
                                                <button onClick={handleChangeAddTag} className="btn btn-smaller ml-3"  > Add </button>
                                            </div>
                                        </div>


                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="input-group form-group" data-tip="The NFT resource. The resource will be uploaded on IPFS through pinata.cloud API">
                                        <label>Item Media  <span className="font-size-12" > ( *required; image and video accepted ) </span> </label>
                                        <div className="custom-file">
                                            <input type="file" accept="video/*,image/*" required="required" className={'form-control ' + (selectedFileError ? 'is-invalid' : '')} id="inputGroupFile01" onChange={handleChangeItemImagefile} />

                                            <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                        </div>
                                        {selectedFileError && <p className="col-12 color-red p-0 m-0" > The Image file is required </p>}
                                    </div>
                                </div>

                                <div className="col-12 mb-2 p-0">
                                    <div className="row p-0" data-tip="Choose how you want to input the NFT attributes">
                                        <div className="col-auto col-md-8" > Item Metadata </div>
                                        <div className="col-auto text-right" >
                                            {metadataSwitch && <p> Upload </p>}
                                            {!metadataSwitch && <p> Manual </p>}

                                        </div>
                                        <div className="col-auto text-right" >
                                            <Switch height={25} width={50} onChange={handleSwitchChange} checked={metadataSwitch} />

                                        </div>
                                    </div>
                                </div>

                                {!metadataSwitch &&
                                    <div className="col-12 ">
                                        <div className="form-group">
                                            <label>Add property name and value</label>

                                            <div className="row" >
                                                <div className="col-12 mt-3 p-0" >
                                                    <input type="text" className="form-control" name="royality" placeholder="Add property name" value={PropertyName} onChange={handleChangePropertyName} />
                                                </div>
                                                <div className="col-12 mt-3 p-0" >
                                                    <input type="text" className="form-control" name="royality" placeholder="Add property value" value={PropertyValue} onChange={handleChangePropertyValue} />
                                                </div>
                                                <div className="col-12 mt-3 p-0" >
                                                    <button onClick={handleChangeProperty} className="btn btn-smaller "  > Add Property </button>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                }

                                {metadataSwitch &&
                                    <>
                                        <div className="col-12">
                                            <div className="input-group form-group">
                                                <label>Media Metadata ( json file )</label>
                                                <div className="custom-file" data-tip="Upload the metadata.json file for this NFT. The resource will be uploaded on IPFS through pinata.cloud API. The obtained hash is included in the attributes">
                                                    <input type="file" accept="*" className="custom-file-input" id="inputGroupFile01" onChange={handleChangeItemMetafile} />
                                                    <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-12 d-none">
                                            <div className="input-group form-group">
                                                <label>Collection Metadata ( json file ) </label>
                                                <div className="custom-file">
                                                    <input type="file" accept="*" className="custom-file-input" id="inputGroupFile01" onChange={handleChangeItemCollectionfile} />
                                                    <label className="custom-file-label" htmlFor="inputGroupFile01">Choose file</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }

                                <div className="col-12 mb-4">
                                    <button className="btn w-100 mt-3 mt-sm-4" onClick={handleSubmit}  >Create Item</button>
                                </div>
                                {/* RIGHT */}
                            </div>



                        </div>
                    </form>
                }


            </div>

        </div>
        <ReactTooltip />
    </section>
}

export default CreateCollectionItem;