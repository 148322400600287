import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";

import NFTDetails from '../../components/ItemDetails/ItemDetails';

import { getNFTDetails } from "../../helpers/asyncRequests";

const NftDetailsPage = () => {
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [nftDetails, setNftDetails] = useState();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { tokenIdentifier } = Object.fromEntries(query);
    const history = useHistory();

    const initData = () => {
        if (!componentDidMount && tokenIdentifier) {
            getNFTDetails(tokenIdentifier).then(({ data }) => {
                if( data != undefined ){
                    // let parsedAttributes = Buffer.from(data.attributes, "base64").toString()
                    //     .split(';')
                    //     .map(x => x.split(':'));
                    // data.attributes = parsedAttributes;
                    data.attributes = [];
                    setNftDetails(data);
                }else{
                    //history.push('/wallet')
                }
                    
                
            })
            setComponentDidMount(true);
        }
    }

    useEffect(initData, []);

    return (
        <div className="main">
            {componentDidMount && nftDetails && nftDetails?.uris
                && <NFTDetails nftDetails={nftDetails} />
            }
        </div>
    );
}
export default NftDetailsPage;