import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";

import GiveawayComponent from '../../components/Giveaway/Giveaway';

//import { getNFTDetails } from "../../helpers/asyncRequests";

const Giveaway = () => {
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [giveawayDetails, setgiveawayDetails] = useState();
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { id } = Object.fromEntries(query);
    
    const initData = () => {
        if (!componentDidMount && id) {
            
            console.log(id)
            const data = require('../../db/giveaways.json')
            let found = null
            data.forEach((giveaway)=>{
                if( giveaway.id == id ){
                    found = giveaway
                }
            })
            setgiveawayDetails(found);
            //console.log(found);
            setComponentDidMount(true);
        }
    }

    useEffect(initData, []);

    return (
        <div className="main">
            {componentDidMount  && <GiveawayComponent giveawayDetails={giveawayDetails} />
            }
        </div>
    );
}
export default Giveaway;