import React from "react";
import { dAppName } from "./config";
import withPageTitle from "./components/PageTitle";
import Hero from "./components/Hero/hero";
import WalletConnect from "./components/Wallet/Wallet.js";
import WalletInventory from "./pages/WalletInventory/WalletInventory.js";
import Wallet from "./pages/WalletInventory/Wallet.js";
import LoadWallet from "./pages/WalletInventory/LoadWallet.js";
import WalletItem from "./pages/WalletInventory/WalletItemDetails.js";
import TransferItem from "./pages/WalletInventory/TransferItem.js";
import Home from "./pages/Home/index";
import Explore from "./pages/Explore/Explore.js";
import CreateCollection from "./pages/Collections/Create.js";
import CreateCollectionItem from "./components/ItemDetails/Create.js";
import MyCollections from "./pages/MyCollections/MyCollections.js";
import MyCollectionItems from "./pages/MyCollections/MyCollectionItems.js";
import HowItWorks from "./pages/HowItWorks/HowItWorks.js";
import Giveaways from "./pages/Giveaways/Giveaways.js";
import Giveaway from "./pages/Giveaways/Giveaway.js";
import Author from "./pages/Author/Author.js";
import Transaction from "./pages/Transaction";
import MyContracts from "./pages/MyContracts/MyContracts";
import CreateContract from "./pages/MyContracts/Create";
import ContractCheckout from "./pages/MyContracts/ContractCheckout";

export const routeNames = {
  home: "/",
  home2: "/home",
  collections: "/my-collections",
  transaction: "/transaction",
  ledger: "/ledger",
  walletConnect: "/unlock",
  walletInventory: "/wallet-inventory",
  LoadWallet: "/load-wallet",
  WalletItem: "/item",
  TransferItem: "/transfer-item",
  // roadmap: "/roadmap",
  wallet: "/wallet",
  hero: "/hero",
  explore: "/explore",
  Author: "/author",
  CreateCollection: "/create-collection",
  CreateCollectionItem: "/create-item",
  MyCollections: "/my-collections",
  MyCollectionItems: "/collection-items",
  HowItWorks: "/how-it-works",
  Giveaways: "/giveaways",
  Giveaway: "/giveaway",
  auction: "/whitelisted-auction",
  myContracts: "/my-contracts",
  createContract: "/create-contract",
  contractInfo: "/contract-info",
  contractDetailsCheckout: "/contract-checkout"
};

const routes = [
  {
    path: "/",
    title: "Home",
    component: Home,
  },
  {
    path: routeNames.home2,
    title: "Home",
    component: Home,
  },
  // {
  //   path: routeNames.collections,
  //   title: "Collections",
  //   component: Collections
  // },
  // {
  //   path: routeNames.roadmap,
  //   title: "Roadmap",
  //   component: Roadmap
  // },
  // {
  //   path: routeNames.wallet,
  //   title: "Wallet",
  //   component: Wallet
  // },
  {
    path: routeNames.hero,
    title: "Hero",
    component: Hero,
  },
  {
    path: routeNames.explore,
    title: "Explore",
    component: Explore,
  },
  {
    path: routeNames.Author,
    title: "Author",
    component: Author,
  },
  {
    path: routeNames.walletConnect,
    title: "Wallet Connect",
    component: WalletConnect,
  },
  {
    path: routeNames.walletInventory,
    title: "Wallet Inventory",
    component: WalletInventory,
  },
  {
    path: routeNames.wallet,
    title: "Wallet",
    component: Wallet,
  },
  {
    path: routeNames.LoadWallet,
    title: "Explore Wallet",
    component: LoadWallet,
  },
  {
    path: routeNames.WalletItem,
    title: "Wallet Item",
    component: WalletItem,
  },
  {
    path: routeNames.TransferItem,
    title: "Transfer Item",
    component: TransferItem,
  },
  {
    path: routeNames.transaction,
    title: "Transaction",
    component: Transaction,
  },
  {
    path: routeNames.CreateCollection,
    title: "Create Collection",
    component: CreateCollection,
    authenticatedRoute: true
  },
  {
    path: routeNames.CreateCollectionItem,
    title: "Create Collection Item",
    component: CreateCollectionItem,
  },
  {
    path: routeNames.MyCollections,
    title: "My Collections",
    component: MyCollections,
  },
  {
    path: routeNames.MyCollectionItems,
    title: "Collection items",
    component: MyCollectionItems,
  },
  {
    path: routeNames.HowItWorks,
    title: "How It Works",
    component: HowItWorks,
  },
  {
    path: routeNames.Giveaways,
    title: "Giveaways",
    component: Giveaways,
  },
  {
    path: routeNames.Giveaway,
    title: "Giveaway",
    component: Giveaway,
  },
  {
    path: routeNames.myContracts,
    title: "My Contracts",
    component: MyContracts,
  },
  {
    path: routeNames.createContract,
    title: "Create a new contract",
    component: CreateContract,
  },
  {
    path: routeNames.contractDetailsCheckout,
    title: "Create a new contract",
    component: ContractCheckout,
  },
  // {
  //   path: routeNames.transactionFromAuction,
  //   title: "Transaction",
  //   component: TransactionFromAuction,
  // },
  // {
  //   path: routeNames.auction,
  //   title: "Whitelisted auction",
  //   component: Auction
  // },
  // {
  //   path: routeNames.pixelArtCollections,
  //   title: "Pixel Art",
  //   component: PixelArt
  // },
  // {
  //   path: routeNames.voxelArtCollections,
  //   title: "Voxel Art",
  //   component: VoxelArt
  // },
  // {
  //   path: routeNames._3dModelCollections,
  //   title: "3D Models",
  //   component: ThreeDModel
  // },
  // {
  //   path: routeNames.drawingArtCollections,
  //   title: "Drawing Art",
  //   component: DrawingArt
  // },
];

const wrappedRoutes = () =>
  routes.map((route) => {
    const title = route.title
      ? `${route.title} • ${dAppName}`
      : `${dAppName}`;
    return {
      path: route.path,
      authenticatedRoute: Boolean(route?.authenticatedRoute),
      component: withPageTitle(
        title,
        route.component,
      ) as any as React.ComponentClass<any, any>,
    };
  });

export default wrappedRoutes();
