import React, { useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { getWalletNFTs, getNftsInCollection } from "../../helpers/asyncRequests";
import WalletNFTGallery from "../WalletInventory/WalletGallery";
//import TransferNFTModal from "./TransferNFTModal";
import WalletHeader from "../WalletInventory/WalletHeader";

import { db } from "../../Firebase.js";
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import CollectionsHeader from "./CollectionsHeader";

const Inventory = () => {

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { collectionTicker } = Object.fromEntries(query);
    const { collectionName } = Object.fromEntries(query);
    const history = useHistory();

    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [inputAddress, setInputAddress] = useState();
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [walletNfts, setWalletNfts] = useState([]);
    const [shouldShowList, setShouldShowList] = useState(false);
    //erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg
    //erd1964efrfe0h0rcg9vck4c4wdmesvatm6860mj8dyk2neudnkuk5jqudz3lz

    const loadNfts = (collectionTicker) => {
        //CHANGE getWalletNFTs FUNCTIOn
        //console.log( address )
        let walletNfts = getNftsInCollection({ ticker: collectionTicker }).then(({ data }) => {
            if (data != undefined) {
                return data;
            }

        });
        return walletNfts;
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        // if (loggedIn) {

        //     if (collectionTicker == undefined) {
        //         history.push('/my-collections')
        //     } else {
        //         loadNfts(collectionTicker).then((val) => {
        //             setWalletNfts(val);
        //             setShouldShowList(true);
        //         });

        //         //getCollectionData( collectionTicker )

        //     }

        // }
        // else {
        //     history.push('/wallet-connect')
        // }
        if (collectionTicker == undefined) {
            console.log('asa da');
            history.push('/my-collections')
        } else {
            console.log('asa daaaaa');
            loadNfts(collectionTicker).then((val) => {
                setWalletNfts(val);
                setShouldShowList(true);
            });

            //getCollectionData( collectionTicker )

        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <CollectionsHeader ticker={collectionTicker} />

            <div className="row">
                <div className="col-12 col-md-auto text-center text-md-left">
                    <div className="intro">
                        <div className="intro-content ">
                            <h3 className="mt-3 mb-0"> {collectionName} </h3>
                            <p className="mt-0 mb-0">{collectionTicker}</p>
                        </div>
                    </div>
                </div>
            </div>

            {shouldShowList && walletNfts?.length == 0 &&
                <div className="row">
                    <div className="col-12">
                        <h3 className="mt-5 mb-0">Collection is empty.</h3>
                    </div>
                </div>
            }

            {shouldShowList && walletNfts?.length > 0
                ? <WalletNFTGallery walletNfts={walletNfts} />
                : <div className="media-preloader pt-5" > <div className="loader"></div> </div>
            }

            {/* {shouldShowList && walletNfts?.length > 0 &&
                walletNfts.map((w, i) => {
                    //return <TransferNFTModal key={i} nftDetails={w} />;
                })
            } */}
        </div>
    </section>
}

export default Inventory;