import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as config from "./config";
import Layout from "./components/Layout";
import routes, { routeNames } from "./routes";
import PageNotFound from "./components/PageNotFoud";
import Connect from "./pages/Connect";
import MaiarConnect from "./pages/Connect/maiar";
import LedgerConnect from "./pages/Connect/ledger";
import { DappUI, DappProvider } from '@elrondnetwork/dapp-core';

function App() {
  const {
    TransactionsToastList,
    // SignTransactionsModals,
    NotificationModal,
    DappCorePages: { UnlockPage }
  } = DappUI;
  return (
    <>
      <Router basename={process.env.PUBLIC_URL}>
          <DappProvider
            environment={config.network.environment}
            customNetworkConfig={{ name: 'customConfig', apiTimeout: 6000 }}
          >
            <Layout>
              <TransactionsToastList />
              <NotificationModal />

              <Switch>
                <Route
                  path={routeNames.walletConnect}
                  component={Connect}
                  exact={true}
                />
                <Route
                  path="/ledger" /* must correspond to ledgerRoute */
                  component={LedgerConnect}
                  exact={true}
                />
                <Route
                  path="/walletconnect" /* must correspond to walletConnectRoute */
                  component={MaiarConnect}
                  exact={true}
                />
                {routes.map((route, i /* rest of routes */) => (
                  <Route
                    path={route.path}
                    key={route.path + i}
                    component={route.component}
                    exact={true}
                  />
                ))}
                <Route component={PageNotFound} />
              </Switch>
            </Layout>
          </DappProvider>
      </Router>
    </>
  );
}

export default App;
