import React, { useState } from "react";
import TransferItem from '../../pages/WalletInventory/TransferItem';
import TransferLoadedItem from '../../components/ItemDetails/TransferItem';

const Modal = ( {modalDetails, parentStateSetter} ) => {
    const [menuState, setmenuState] = useState(false);
    
    //console.log( modalDetails )
    //console.log( parentStateSetter )
    
    const toggle = (e) => {
        
        //let element = document.getElementById("modal");
        let element = document.getElementById("modal-"+modalDetails.details.tokenIdentifier);
        //element.remove();
        
        if( element.classList.contains("show") ){
            //setmenuState(false)
            element.classList.remove("show");
            element.classList.remove("d-block");
            
            parentStateSetter();
        }else{
            //setmenuState(true)
            element.classList.add("show");
            element.classList.add("d-block");
        }
        
        
        
    }
    
    React.useEffect(() => {
        //let element = document.getElementById("modal-"+modalDetails.modalDetails.details.tokenIdentifier);
        //element.classList.add("show");
        //element.classList.add("d-block");
        toggle()
    }, []);
    
    return (
        <div id={`modal-${modalDetails.details.tokenIdentifier}`} className="modal fade p-0" >
            <div className="modal-dialog modal-dialog-wide dialog-animated h-100">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        { modalDetails.target == 'trasnferItem' && <h4 className="m-0" > Transfer Item </h4>  }
                        <i className="far fa-times-circle icon-close cursor-pointer" onClick={toggle} />                        
                    </div>
                    <div className="menu modal-body">
                        <div className="row w-100 text-center">
                            
                            <TransferLoadedItem nftDetails={modalDetails.details} />
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;