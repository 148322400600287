import React, { useState, useEffect } from "react";
import {Link, useLocation, useHistory } from "react-router-dom";
//import TransferNFTModal from "./TransferNFTModal";
import ReactTooltip from "react-tooltip";

import { db } from "../../Firebase.js";
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";

const CollectionsHeader = ({ticker}) => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [inputAddress, setInputAddress] = useState();
    const [componentDidMount, setComponentDidMount] = useState(false);
    const history = useHistory();
    //erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg
    //erd1964efrfe0h0rcg9vck4c4wdmesvatm6860mj8dyk2neudnkuk5jqudz3lz

    let pathname = window.location.pathname;
    useEffect(() => {
        pathname = window.location.pathname;
    }, [window.location.pathname]);
    

    const copyToClipboard = (e) =>{
        e.preventDefault();
        navigator.clipboard.writeText( address )
        
        toast("Address copied to clipboard", {
            position: "bottom-right",
            toastId: 'copy-address',
            transition: Slide,
            theme: "dark"
            //className: 'foo-bar'
        });
    }
    
    React.useEffect(() => {
        if (componentDidMount)
            return;
        setComponentDidMount(true);
    }, []);

    return (
    <>
            <div className="row">
                <div className="col-12">
                    {/* Intro */}
                    <div className="intro text-center">
                        <div className="intro-content text-center">
                            <span>Collection Details</span>
        
                            <h3 className="mt-3 mb-3">Explore the {ticker} collection</h3>
        
                            {/* <p className="mt-3 mb-0 copy-box" data-tip={address} onClick={(e) => copyToClipboard(e)} > 
                                {address.substring(0, 10) }{ address.length >= 16 && `...` }{address.substring(address.length - 6, address.length) } 
                            </p>
                            <ReactTooltip />
                            <ToastContainer transition={Slide} /> */}
                                
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="item-form no-hover mt-4 mb-4">
                <div className="row navbar navbar-expand">
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item"  >
                            <Link to="/wallet" className={`${pathname.match('/wallet') ? 'active nav-link' : 'nav-link'} `}  >  Wallet Items </Link>
                        </li>
                        <li className="nav-item d-none" >
                            <Link to="/wallet-collections" className={`${pathname.match('/wallet-collection') ? 'active nav-link' : 'nav-link'} `} >  Wallet Collections </Link>
                        </li>
                        <li className="nav-item"  >
                            <Link to="/my-collections" className={`${pathname.match('/my-collections') ? 'active nav-link' : 'nav-link'} `}  >  My collections </Link>
                        </li>
                        <li className="nav-item"  >
                            <Link to="/my-contracts" className={`${pathname.match('/my-contracts') ? 'active nav-link' : 'nav-link'} `}  >  My contracts </Link>
                        </li>
                    </ul>
                    
                    
                    
                </div>
                
            </div> */}
    </>
    )
}

export default CollectionsHeader;