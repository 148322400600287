import React, { useState } from "react";
import Menu from "../Layout/Navbar/Menu.js";


const ModalMenu = () => {
    const [menuState, setmenuState] = useState(false);
    
    const toggleMenu = (e) => {
        
        let element = document.getElementById("menu");
        if( element.classList.contains("show") ){
            //setmenuState(false)
            element.classList.remove("show");
            element.classList.remove("d-block");
        }else{
            //setmenuState(true)
            element.classList.add("show");
            element.classList.add("d-block");
        }

    }
    
    return (
        <div id="menu" className="modal fade p-0">
            <div className="modal-dialog dialog-animated">
                <div className="modal-content h-100">
                    <div className="modal-header" data-dismiss="modal">
                        Menu <i className="far fa-times-circle icon-close" onClick={toggleMenu} />
                    </div>
                    <div className="menu modal-body">
                        <div className="row w-100 text-center">
                            
                            {/* Menu */}
                            <Menu />
        
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalMenu;