import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/netstorm-json/work";

class Work extends Component {
    state = {
        data: {},
        workData: []
    }
    componentDidMount() {
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    workData: res.data.workData
                })
                // console.log(this.state.data)
            })
            .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="work-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/* Intro */}
                            <div className="intro mb-4">
                                <div className="intro-content">
                                    <span>How it works?</span>
                                    <h3 className="mt-3 mb-0">Create your own NFTs</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row items">
                        <div className="col-12 col-sm-6 col-lg-4 item">
                            <div className="single-work">
                                <i className="icons icon-wallet text-effect" />
                                <h4>Set up your wallet</h4>
                                <p>Once you’ve set up your Elrond Wallet, connect to erdNFT by clicking the WalletConnect button in the top right corner.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 item">
                            <div className="single-work">
                                <i className="icons icon-grid text-effect" />
                                <h4>Create your collection</h4>
                                <p>Click Create and set up your collection. Choose an identifier and a ticker to represent it, set your roles and start minting.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-6 col-lg-4 item">
                            <div className="single-work">
                                <i className="icons icon-drawer text-effect" />
                                <h4>Add your NFTs</h4>
                                <p>Upload your work (image, video, audio, or 3D art), add a title, a description, set royalties, and customize your NFTs with properties, stats, and unlockable content.</p>
                            </div>
                        </div>
                        {/* <div className="col-12 col-sm-6 col-lg-4 item">
                            <div className="single-work">
                                <i className="icons icon-bag text-effect" />
                                <h4>Add your NFTs</h4>
                                <p>Upload your work (image, video, audio, or 3D art), add a title, a description, set royalties, and customize your NFTs with properties, stats, and unlockable content.</p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        );
    }
}

export default Work;