import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getIssuedCollections, getCollectionsWithCreateRole } from "../../helpers/asyncRequests";
import { getAssignCreatorRoleTransaction } from "../../helpers/transactions";
//import WalletNFTGallery from "./WalletGallery";
//import TransferNFTModal from "./TransferNFTModal";
import { useHistory } from 'react-router-dom';
import WalletHeader from "../WalletInventory/WalletHeader";
import ReactTooltip from "react-tooltip";


//
import { db } from "../../Firebase.js";
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';
import { sendTransactions, useGetAccountInfo } from "@elrondnetwork/dapp-core";
//

const initData = {
    pre_heading: "Explore",
    heading: "Exclusive Digital Assets",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    btnText: "Load More"
}

//const data = require('../../db/giveaways.json')

const MyCollections = () => {
    const history = useHistory();
    const { address } = useGetAccountInfo();
    // const address = 'erd1qqqqqqqqqqqqqpgqjpt0qqgsrdhp2xqygpjtfrpwf76f9nvg2jpsg4q7th';
    const loggedIn = Boolean(address);
    const [inputAddress, setInputAddress] = useState();
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [walletCollections, setwalletCollections] = useState([]);
    const [shouldShowList, setShouldShowList] = useState(false);

    const loadCollections = (address) => {
        let walletCollections = getIssuedCollections({ address }).then(({ data }) => {
            if (data != undefined) {
                //console.log(data)
                return data
            }

        });
        return walletCollections;
    }

    const loadCollectionsWithCreateRole = (address) => {
        let resp = getCollectionsWithCreateRole({ address }).then(({ data }) => {
            if (data != undefined) {
                // console.log(data)
                return data
            }

        });
        return resp;
    }

    const assignRole = async (tokenIdentifier) => {

        //console.log( tokenIdentifier )
        //console.log( address )
        let transaction = getAssignCreatorRoleTransaction(tokenIdentifier, address);
        //console.log( transaction )
        // await sendTransactions({ transactions: transaction, callbackRoute: "/transaction" });
        const { sessionId /*, error*/ } = await sendTransactions({
            transactions: transaction,
            transactionsDisplayInfo: {
                processingMessage: 'Buying NFT',
                errorMessage: 'An error has occured during buying',
                successMessage: 'Transaction successful',
                transactionDuration: 10000
            }
        });
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {

            let user = {
                wallet: "MAIAR",
                address: address,
                datetime: Math.round(+new Date() / 1000)
            }
            setDoc(doc(db, "users", address), user, { merge: true })

            loadCollections(address).then((val) => {

                //console.log(val)
                setwalletCollections(val);
                setShouldShowList(true);

            });

        }
        else {
            history.push('/wallet-connect')
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area ">
        <div className="container">

            <WalletHeader />

            <div className="row justify-content-center">
                <div className="col-12 col-md-6 ">
                    {/* Intro */}
                    <div className="intro text-center text-md-left">
                        <h3 className="mt-3 mb-0">My collections</h3>
                    </div>
                </div>
                <div className="col-12 col-md-6 ">
                    {/* Intro */}
                    <div className="intro text-center text-md-right">
                        <Link to="/create-collection" className="btn ml-lg-auto btn-bordered-white mt-3"  > <i className="icon-plus mr-2" /> Create collection </Link>
                    </div>
                </div>
            </div>

            {!shouldShowList &&
                <div className="media-preloader pt-5" > <div className="loader"></div> </div>
            }

            {walletCollections?.length == 0 &&
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mt-5 mb-0">Wallet does not have any collections. Create one now</h3>
                    </div>
                </div>
            }

            {walletCollections?.length > 0 &&

                <div className="row">
                    <div className="col-12">

                    </div>
                    <div className="col-12">

                        {walletCollections.map((item, idx) => {
                            return (
                                <div className="row card-bg p-1 p-md-4" key={`exf_${idx}`}>

                                    <div className="col-12 col-md-4 pt-2 pt-md-0">
                                        <h4 className="m-0 card-bg">{item.name}</h4>
                                        <h5 className="mt-3 mb-0 card-bg">{item.ticker}</h5>
                                    </div>

                                    <div className="col-12 col-md-2 d-flex align-items-center">
                                        {item.type === 'NonFungibleESDT' && 'NFT Collection'}
                                        {item.type === 'SemiFungibleESDT' && 'SFT Collection'}
                                        {item.type === 'MetaESDT' && 'Meta ESDT'}
                                    </div>

                                    <div className="col-12 col-md-6 text-center text-md-right pb-2 pb-mb-0">

                                        {item.canCreate == false &&
                                            <a className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 mr-2" onClick={() => assignRole(item.ticker)}  > Assign Role </a>
                                        }

                                        {item.canCreate == true &&
                                            <>
                                                {item.type === 'NonFungibleESDT' && <Link to={`/create-item?collectionTicker=${item.ticker}`} className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 mr-2"  > Create Item </Link>}
                                                {item.type !== 'NonFungibleESDT' && <a to={`#`} data-tip="Coming soon for SFTs and Meta ESDTs" className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 mr-2"> Create Item </a>}
                                            </>
                                        }

                                        {item.type !== 'MetaESDT' && <Link to={`/collection-items?collectionTicker=${item.ticker}&collectionName=${item.name}`} className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 "  > See Items </Link>}
                                        {item.type === 'MetaESDT' && <a to={`#`} data-tip="Coming soon for Meta ESDTs" className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 mr-2"> See Items </a>}

                                    </div>

                                </div>
                            );
                        })}

                    </div>
                    <ReactTooltip />
                </div>
            }
        </div>
    </section>

}

export default MyCollections;