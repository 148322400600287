import React from 'react';
import ReactTooltip from 'react-tooltip';
import { getAccountDetails } from '../../helpers/asyncRequests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faX } from '@fortawesome/free-solid-svg-icons'

const ContractDetailsRow = ({ address }) => {
    const [contractDetails, setContractDetails] = React.useState();
    React.useEffect(() => {
        getAccountDetails({ address }).then((res) => {
            console.log(res.data);
            setContractDetails(res.data);
        });
    }, []);

    const iconComponent = (prop) => {
        return prop
            ? <FontAwesomeIcon icon={faCheck} style={{ color: 'green' }} />
            : <FontAwesomeIcon icon={faX} style={{ color: 'red' }} />;
    };

    return <div className="row card-bg p-1 p-md-4">

        <div className="col-12 col-md-4 pt-2 pt-md-0 d-flex justify-content-center mb-2">
            <h4 className="m-0 card-bg">erd1...{address.substring(48)}</h4>
            {/* <h5 className="mt-3 mb-0 card-bg">{item.ticker}</h5> */}
        </div>
        {contractDetails !== undefined
            ? <>
                <div className="col-2 col-md-1"></div>
                <div className="col-2 col-md-1" data-tip={`isUpgradeable: ${contractDetails.isUpgradeable}`}>
                    {iconComponent(contractDetails.isUpgradeable)}
                </div>
                <div className="col-2 col-md-1" data-tip={`isReadable: ${contractDetails.isReadable}`}>
                    {iconComponent(contractDetails.isReadable)}
                </div>
                <div className="col-2 col-md-1" data-tip={`isPayable: ${contractDetails.isPayable}`}>
                    {iconComponent(contractDetails.isPayable)}
                </div>
                <div className="col-2 col-md-1" data-tip={`isPayableBySmartContract: ${contractDetails.isPayableBySmartContract}`}>
                    {iconComponent(contractDetails.isPayableBySmartContract)}
                </div>
                <div className="col-2 col-md-1"></div>
                <ReactTooltip />
            </> : <div className='col-6 d-flex justify-content-center'><span>Loading data</span></div>}
        <div className="col-12 col-md-2 d-flex justify-content-center">
            <a className="btn btn-smaller ml-lg-auto btn-bordered-white mr-2"> Manage </a>
        </div>
        {/* <div  className="col-12 col-md-6 text-center text-md-right pb-2 pb-mb-0">
        
        { item.canCreate == false &&
            <a  className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 mr-2" onClick={() => assignRole(item.ticker)}  > Assign Role </a>
        }

        { item.canCreate == true &&
            <Link to={`/create-item?collectionTicker=${item.ticker}`} className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 mr-2"  > Create Item </Link>
        }

        <Link to={`/collection-items?collectionTicker=${item.ticker}&collectionName=${item.name}`} className="btn btn-smaller ml-lg-auto btn-bordered-white mt-3 "  > See Items </Link>
    </div> */}
    </div>
}

export default ContractDetailsRow;
