import { useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { routeNames } from "../../routes";

const CreateContract = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [selectedContract, setSelectedContract] = useState();
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!selectedContract) {
            window.alert('Please select the contract type before proceeding');
            return;
        }
        history.push(`${routeNames.contractDetailsCheckout}?id=${selectedContract}`);
    }

    const contractData = [
        {
            id: 1,
            name: 'Individual Auction',
            description: '*allows you to list NFTs in a specific contract. Supports different kind of auctions with configurable parameters.',
            summary: 'Suitable for creating your own auction space.'
        },
        {
            id: 2,
            name: 'Launchpad',
            description: '*various ways of launching an NFT collection, supporting already minted and new collections, with features as whitelisting, planned start/end times, batching, multiple drops in one and many more',
            summary: 'Suitable for launching a new NFT collection on Elrond.'
        },
        {
            id: 3,
            name: 'Store',
            description: '*group NFTs in categories and control what gets sold, when and for what price. Supports ESDTs, SFTs, off-chain payments etc.',
            summary: 'Suitable if you\'re looking to launch an NFT based store.'
        },
        {
            id: 4,
            name: 'Staking',
            description: '*distribute your ESDTs/NFTs/SFTs towards your collection holders while reducing circulating supply.',
            summary: 'Suitable if you\'re looking for an efficient way of distributing rewards to your most loyal holders.'
        },
        {
            id: 5,
            name: 'DAO',
            description: '*decentralize the decision making process for your project/company. Get access to an on-chain API that can enforce decisions made and be one step ahead of your competition.',
            summary: 'Suitable for project management.'
        },
    ];

    const handleCheckBoxChange = (id) => {
        setSelectedContract(id);
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {
            console.log(address)
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <div className="col-12 col-md-8 offset-md-2">

                <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5 text-center">
                    <div className="intro-content">
                        <span>Get Started</span>
                        <h3 className="mt-3 mb-0">Create Contract</h3>
                    </div>
                </div>

                {!loggedIn &&
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className="mt-5 mb-0">You need to connect your wallet first</h3>
                            <Link to={routeNames.walletConnect} className="btn ml-lg-auto btn-bordered-white mt-5"><i className="icon-wallet mr-md-2" />Wallet Connect</Link>
                        </div>
                    </div>
                }

                {loggedIn &&
                    <div className="item-form card no-hover" >
                        {contractData.map((cd, idx) =>
                            <div className="row" key={idx}>
                                <div className="col mt-2">
                                    <div className="d-flex justify-content-between">
                                        <h4>{cd.name}</h4>
                                        <input type='checkbox' className="mt-3" checked={selectedContract == cd.id} onChange={() => handleCheckBoxChange(cd.id)} />
                                    </div>
                                    <p className="card-bg p-0 m-0 font-size-14" >{cd.description}</p>
                                    <p className="card-bg p-0 m-0 font-size-16" ><b>{cd.summary}</b></p>
                                    <a href={`${routeNames.contractInfo}/${cd.id}`} target="_blank" className="card-bg p-0 m-0 font-size-16">Read more</a>
                                </div>
                            </div>)}
                        <div className="row">
                            <div className="col">
                                {selectedContract && <button className="btn w-100 mt-3 mt-sm-4" onClick={handleSubmit} >Create Contract</button>}
                                {!selectedContract && <button className="btn w-100 mt-3 mt-sm-4 disabled">Create Contract</button>}
                                {/* <div className="d-flex justify-content-center">
                                    <button className="btn mt-3 mt-sm-4" onClick={handleSubmit} style={{ width: '90%' }}>Read More</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                }


            </div>

        </div>

    </section>
}

export default CreateContract;