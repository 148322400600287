import React, { useState } from "react";
import { useLocation, Link, useHistory } from "react-router-dom";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import WalletNFTGallery from "./WalletGallery";
import WalletHeader from "./WalletHeader";
//import TransferNFTModal from "./TransferNFTModal";

import { db } from "../../Firebase.js";
import { getFirestore, collection, addDoc, setDoc, doc } from 'firebase/firestore';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Slide, Zoom, Flip, Bounce } from 'react-toastify';
import { useGetAccountInfo } from "@elrondnetwork/dapp-core";

const Inventory = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);
    const [inputAddress, setInputAddress] = useState();
    const [componentDidMount, setComponentDidMount] = useState(false);
    const [walletNfts, setWalletNfts] = useState([]);
    const [shouldShowList, setShouldShowList] = useState(false);
    const history = useHistory();
    //erd1tztluf08g90max7jkr4jtac9w5qv7qacgkhh57q9nz2erq9y2p3sd5njkg
    //erd1964efrfe0h0rcg9vck4c4wdmesvatm6860mj8dyk2neudnkuk5jqudz3lz

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { status, txHash } = Object.fromEntries(query);

    const loadNfts = (address) => {
        let walletNfts = getWalletNFTs({ address }).then(({ data }) => {
            if (data != undefined) {
                let filteredData = data.filter(d => d.type === 'NonFungibleESDT' || d.type === 'SemiFungibleESDT');
                console.log(filteredData);
                return filteredData;
            }

        });
        return walletNfts;
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {

            //console.log( status )
            if (status != undefined) {
                if (status === "success") {

                    toast.success("Transaction submitted successfully", {
                        position: "bottom-right",
                        toastId: 'copy-address',
                        transition: Slide,
                        theme: "dark",
                        //onClose: () => { history.push("/wallet"); }
                    });

                } else {

                    toast.error("Error sending transaction", {
                        position: "bottom-right",
                        toastId: 'copy-address',
                        transition: Slide,
                        theme: "dark",
                        //onClose: () => { history.push("/wallet"); }
                    });

                }
            }

            let user = {
                wallet: "MAIAR",
                address: address,
                datetime: Math.round(+new Date() / 1000)
            }
            setDoc(doc(db, "users", address), user, { merge: true })

            loadNfts(address).then((val) => {
                setWalletNfts(val);
                setShouldShowList(true);
            });
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <WalletHeader />

            {shouldShowList && walletNfts?.length == 0 &&
                <div className="row">
                    <div className="col-12">
                        <h3 className="mt-5 mb-0">Wallet is empty.</h3>
                    </div>
                </div>
            }

            {shouldShowList && walletNfts?.length > 0
                ? <WalletNFTGallery walletNfts={walletNfts} />
                : <div className="media-preloader pt-5" > <div className="loader"></div> </div>
            }

            <ToastContainer transition={Slide} />

        </div>
    </section>
}

export default Inventory;