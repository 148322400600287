import React, { Component } from 'react';
import {Link} from "react-router-dom";

const initData = {
    pre_heading: "erdNFT",
    heading: "Discover, create, collect and mint extraordinary NFTs",
    content: "Explore on the community driven NFT marketplace",
    btn_1: "Explore",
    btn_2: "Create"
}

class Hero extends Component {
    state = {
        data: {}
    }
    componentDidMount(){
        this.setState({
            data: initData
        })
    }
    render() {
        return (
            <section className="hero-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-7">
                            <span>{this.state.data.pre_heading}</span>
                            <h1 className="mt-4">{this.state.data.heading}</h1>
                            <p>{this.state.data.content}</p>
                            {/* Buttons */}
                            <div className="button-group">
                                <Link className="btn btn-bordered-white d-none" to="/explore"><i className="icon-rocket mr-2" />{this.state.data.btn_1}</Link>
                                <Link className="btn btn-bordered-white" to="/create-collection"><i className="icon-note mr-2" />{this.state.data.btn_2}</Link>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-5">
                            
                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default Hero;