import React, { Component } from 'react';
import {Link} from "react-router-dom";

const initData = {
    pre_heading: "Explore",
    heading: "Exclusive Digital Assets",
    content: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    btnText: "Load More"
}

const data = require('../../db/giveaways.json')

class Giveaways extends Component {
    state = {
        initData: {},
        data: []
    }
    componentDidMount(){
        this.setState({
            initData: initData,
            data: data
        })
    };

    shouldShowBasedOnDate(giveawayDetails) {
        let show = true;
        let now = Date.now();
        if(giveawayDetails.startDateTicks){
            let startDate = new Date(giveawayDetails.startDateTicks);
            if(startDate > now){
                show = false;
            }
        }

        let endDate = new Date(giveawayDetails.endDateTicks);
        if(now > endDate){
            show = false;
        }

        return show;
    };

    render() {
        return (
            <section className="explore-area ">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-8 col-lg-7">
                            {/* Intro */}
                            <div className="intro text-center">
                                <span>Giveaways</span>
                                <h3 className="mt-3 mb-0">Get your free NFTs</h3>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 d-flex justify-content-center'>
                            No giveaways for now, come and check later
                        </div>
                    </div>
                        {this.state.data.map((item, idx) => {
                            return (!this.shouldShowBasedOnDate(item) ? null :
                                <div className="row items mt-4 mb-4" key={`exf_${idx}`}>
                                    <div  className="col-12 col-md-4">
                                        <div className="card">
                                            <div className="image-over">
                                                { item.fileType == 'img' && <img className="card-img-top" src={`/img/${item.img}`} alt="" /> }
                                                { item.fileType == 'video' && 
                                                    <video width="100%" height="auto" controls autoPlay={true} loop muted>
                                                        <source src={`/video/${item.img}`} type="video/mp4" />
                                                        Your browser does not support HTML video.
                                                    </video>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-8 ">
                                        {/* Card Caption */}
                                        <div className="card-caption col-12 mt-1 p-0">
                                            {/* Card Body */}
                                            <div className="card-body">
                                                <Link to={`/giveaway?id=${item.id}`} >
                                                    <h3 className="m-0">{item.title}</h3>
                                                </Link>
                                                <div className="decsription d-flex align-items-center my-3 font-size-14">
                                                    <p className="font-size-14" > {item.description} </p> 
                                                </div>
                                                <div className="seller d-flex align-items-center my-3 font-size-14">
                                                    <span>Offered By</span>
                                                    <a target="_blank" href={item.twitterUrl} className="ml-2" >
                                                       {item.owner} 
                                                    </a>
                                                </div>
                                                <div className="row p-0">
                                                    <span className="col-12 col-md-auto border-right-gray pl-0 font-size-12" >{item.price}</span> 
                                                    <span className="col-12 col-md-auto pl-0 font-size-12" > End date: {item.endDate} </span>
                                                </div>
                                                <Link className="btn btn-bordered-white btn-smaller mt-4 btn-w-sm-100" to={`/giveaway?id=${item.id}`}><i className="icon-handbag mr-2" /> Mint now </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}

                    <div className="row d-none">
                        <div className="col-12 text-center">
                            <a id="load-btn" className="btn btn-bordered-white mt-5" href="#">{this.state.initData.btnText}</a>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Giveaways;