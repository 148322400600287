import { sendTransactions, useGetAccountInfo } from "@elrondnetwork/dapp-core";
import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getWalletNFTs } from "../../helpers/asyncRequests";
import { getIssueCollectionTransaction, getAssignCreatorRoleTransaction } from "../../helpers/transactions";
import WalletNFTGallery from "../WalletInventory/WalletGallery";
//import TransferNFTModal from "./TransferNFTModal";
import { routeNames } from "../../routes";

const ContractCheckout = () => {
    const { address } = useGetAccountInfo();
    const loggedIn = Boolean(address);

    const [componentDidMount, setComponentDidMount] = useState(false);
    const [contractInfo, setContractInfo] = React.useState();

    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const { id } = Object.fromEntries(query);
    const history = useHistory();

    React.useEffect(() => {
        let contractName;
        switch(parseInt(id)) {
            case 1: contractName = 'Auction'; break;
            case 2: contractName = 'Launchpad'; break;
            case 3: contractName = 'Store'; break;
            case 4: contractName = 'Staking'; break;
            case 5: contractName = 'DAO'; break;
            default: console.log(id); return;
        }
        delay(1).then(() => {
            let contractDetails = {
                id: id,
                title: contractName,
                subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tempor lorem sit amet nisl scelerisque auctor. Ut iaculis felis ac sapien ornare facilisis.',
                price: 0.0
            };
            setContractInfo(contractDetails);
        });
    }, []);

    const delay = (time) => {
        return new Promise(resolve => setTimeout(resolve, time));
    }

    const handleSubmit = async e => {
        history.push(routeNames.contractDetailsCheckout);
    }

    React.useEffect(() => {
        if (componentDidMount)
            return;
        if (loggedIn) {
            console.log(address)
        }
        setComponentDidMount(true);
    }, []);

    return <section className="explore-area">
        <div className="container">

            <div className="intro mt-5 mt-lg-0 mb-4 mb-lg-5 text-center">
                <div className="intro-content">
                    <span>Deploy contract</span>
                    <h3 className="mt-3 mb-0">Contract checkout</h3>
                </div>
            </div>

            {!loggedIn &&
                <div className="row">
                    <div className="col-12 text-center">
                        <h3 className="mt-5 mb-0">You need to connect your wallet first</h3>
                        <Link to={routeNames.walletConnect} className="btn ml-lg-auto btn-bordered-white mt-5"><i className="icon-wallet mr-md-2" />Wallet Connect</Link>
                    </div>
                </div>
            }

            {!contractInfo &&
                <div className="row">
                    <div className="col-12 text-center">
                        <h5 className="mt-5 mb-0">Loading contract details..</h5>
                        {/* <Link to={routeNames.walletConnect} className="btn ml-lg-auto btn-bordered-white mt-5"><i className="icon-wallet mr-md-2" />Wallet Connect</Link> */}
                    </div>
                </div>
            }

            {loggedIn && contractInfo &&
                <div className="item-form card no-hover" >
                    <div className="row">
                        <div className="col">
                            <h3>{contractInfo.title} Contract</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <p>{contractInfo.subtitle}</p>
                        </div>
                    </div>
                    <br /><br /><br />
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            {contractInfo.price === 0 && <h4>Service fee: FREE</h4>}
                            {contractInfo.price > 0 && <h4>Service fee: {contractInfo.price.toLocaleString()} EGLD</h4>}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <p>By clicking "Proceed" you agree to our <a href="#">terms and conditions</a></p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col d-flex justify-content-center">
                            <button className="btn mt-3 mt-sm-4" style={{width: '33%'}} onClick={handleSubmit}>Proceed</button>
                        </div>
                    </div>
                </div>
            }

        </div>

    </section>
}

export default ContractCheckout;
