
import { DappUI } from "@elrondnetwork/dapp-core";
import { useLocation } from "react-router-dom";
import WalletConnect from "../../components/Wallet/Wallet";
import { routeNames } from "../../routes";

const Connect = () => {
    const { search } = useLocation();  
    const query = new URLSearchParams(search);
    const { from } = Object.fromEntries(query);
    // return <DappUI.DappCorePages.UnlockPage
    //     callbackRoute={from ?? routeNames.collections}
    //     title="Please select your login method:"
    //     lead=""
    //     ledgerRoute={`/ledger${from ? `?from=${from}`: ``}`} /* route after choosing ledger login */
    //     walletConnectRoute={`/walletconnect${from ? `?from=${from}`: ``}`} /* route after choosing Maiar login */
    // />;
    return <WalletConnect />
}

export default Connect;